import React, { Component } from "react";
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Auth from "../../models/Auth";

// redux
import { connect } from 'react-redux';
import { SetMenuManager } from '../Actions/Menu_Actions';

class MenuList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      views: [], // Initial state
    };
  }

  componentDidMount() {
    // Simulate fetching the views from an external function
    const viewsFromFunction = Auth.getPermissionsModules(); // This can be an async call or an external function
    this.setState({ views: viewsFromFunction });
  }

  // Genérica para cambiar el estado del menú
  changeMenuState = (index) => {
    const menuArray = Array(11).fill("");  // Crea un arreglo con 8 elementos vacíos
    menuArray[index] = "submit";  // Establece "submit" en el índice especificado

    // Actualiza el estado global y el localStorage
    this.props.SetMenuManager(menuArray);
    localStorage.setItem("menu", JSON.stringify(menuArray));
  };

  // Módulo para mostrar elementos según permisos del usuario
  modulesElementByUser = () => (
    <>
      {this.state.views.includes('validate_modify_terms') && (
        <li className={this.props.disabled ? "disabled" : "enabled"}>
          <Link
            className={this.props.menu[10]}
            onClick={() => this.changeMenuState(10)}
            id="controladminusers"
            to="/validate_modify_terms"
          >
            <span className="icon dripicons-network-1" />
            <b>Modificar Plazo Cuotas</b>
          </Link>
        </li>
      )}

      {this.state.views.includes('log_modificacion') && (
        <li className={this.props.disabled ? "disabled" : "enabled"}>
          <Link
            className={this.props.menu[11]}
            onClick={() => this.changeMenuState(11)}
            id="controladminusers"
            to="/log_modificacion"
          >
            <span className="icon dripicons-graph-bar" />
            <b>Log modificación plazo</b>
          </Link>
        </li>
      )}


      {this.state.views.includes('reports') && (
        <li className={this.props.disabled ? "disabled" : "enabled"}>
          <Link className={this.props.menu[8]} onClick={() => this.changeMenuState(8)} id="reports"
            to="/reports">
            <span className="icon dripicons-document" />
            <b>Informes</b>
          </Link>
        </li>
      )}


      {this.state.views.includes('settings') && (
        <li className={this.props.disabled ? "disabled" : "enabled"}>
          <Link className={this.props.menu[9]} onClick={() => this.changeMenuState(9)} id="settings"
            to="/settings">
            <span className="icon dripicons-gear" />
            <b>Parametrización</b>
          </Link>
        </li>
      )}
    </>
  );

  render() {
    return (
      <ul className="submenu">
        <li className={this.props.disabled ? "disabled" : "enabled"}>
          <Link
            className={this.props.menu[0]}
            onClick={() => this.changeMenuState(0)}
            id="manager"
            to="/manager"
          >
            <span className="icon dripicons-toggles" />
            <b>Manager</b>
          </Link>
        </li>
        <li className={this.props.disabled ? "disabled" : "enabled"}>
          <Link className={this.props.menu[1]} onClick={() => this.changeMenuState(1)} id="certificates"
            to="/certificates">
            <span className="icon dripicons-copy" />
            <b>Certificados</b>
          </Link>
        </li>
        <li className={this.props.disabled ? "disabled" : "enabled"}>
          <Link className={this.props.menu[2]} onClick={() => this.changeMenuState(2)} id="AdminUser" to="/adminuser">
            <span className="icon dripicons-user-group" />
            <b>Usuarios</b>
          </Link>
        </li>
        <li className={this.props.disabled ? "disabled" : "enabled"}>
          <Link className={this.props.menu[3]} onClick={() => this.changeMenuState(3)} id="transactions"
            to="/pagos">
            <span className="icon dripicons-card" />
            <b>Pagos</b>
          </Link>
        </li>
        <li className={this.props.disabled ? "disabled" : "enabled"}>
          <Link className={this.props.menu[4]} onClick={() => this.changeMenuState(4)} id="transactions"
            to="/conciliacion">
            <span className="icon dripicons-graph-line" />
            <b>Conciliacion</b>
          </Link>
        </li>
        <li className={this.props.disabled ? "disabled" : "enabled"}>
          <Link className={this.props.menu[5]} onClick={() => this.changeMenuState(5)} id="pages"
            to="/pages">
            <span className="icon dripicons-copy" />
            <b>Paginas</b>
          </Link>
        </li>
        <li className={this.props.disabled ? "disabled" : "enabled"}>
          <Link className={this.props.menu[6]} onClick={() => this.changeMenuState(6)} id="sections"
            to="/sections">
            <span className="icon dripicons-pencil" />
            <b>Secciones</b>
          </Link>
        </li>
        <li className={this.props.disabled ? "disabled" : "enabled"}>
          <Link className={this.props.menu[7]} onClick={() => this.changeMenuState(7)} id="controladminusers"
            to="/controladminusers">
            <span className="icon dripicons-user" />
            <b>Admin Usuarios</b>
          </Link>
        </li>


        {/* Modulos de usuarios autentificados */}
        {this.modulesElementByUser()}

      </ul>
    );
  }
}

MenuList.propTypes = {
  disabled: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  menu: state.menu.menu
})

export default connect(mapStateToProps, { SetMenuManager })(MenuList);
