import React, { Component } from "react";
import HeaderPrivate from "../PrivateViews/HeaderPrivate";
import MenuList from "../AdminViews/MenuList";
import Footer from "../PublicViews/Footerpersonalizado";
import { Messages } from "../../models/Messages";
import Error from "../PublicViews/Error";
import StaticPageStatus from "../SharedViews/StaticPageStatus";


class AdminLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            open: false,
            error_message: Messages.errorTryLater,
            error_message_pop: false,
        }
    }
    handleErrorMessage = (error_message_pop = "") => {
        this.setState({ error_message_pop: error_message_pop });
    };
    
    handleClose = () => {
        this.setState({ open: false });
    };

    render() {
        return (
            <div className="container-fluid">
                <div className="sidebar">
                    <MenuList disabled={this.state.loading} />
                </div>
                <div className="row">
                    <div className="col private-wrapper">
                        <HeaderPrivate menu_list={this.state.loading} />
                        <Error
                            open={!!this.state.error_message_pop}
                            error_message={this.state.error_message_pop}
                            handleClose={() => this.handleErrorMessage()}
                        />
                        <div className="wrapper">
                            <StaticPageStatus>
                                {this.props.children}
                            </StaticPageStatus>
                        </div>
                    </div>
                </div>
                <Footer />
                <Error
                    open={this.state.open}
                    error_message={this.state.error_message}
                    handleClose={this.handleClose}
                />
            </div>
        );
    }
}

export default AdminLayout;