import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import HeaderLogo from './HeaderLogo'
// import Footer from './Footer';
import Footer from "./Footerpersonalizado";
import Error from './Error'
import settings from '../../settings'
import FetchResponses from '../../models/FetchResponses'
import StaticPageStatus from "../SharedViews/StaticPageStatus";
import Carrusel from './Carrusel';
import { Messages } from '../../models/Messages';



class Consult extends Component {

  constructor(props) {
    super(props)
    this.state = {
      fiscal_number: '',
      open: false,
      error_message: '',
      recaptcha_token: '03AGdBq278DPlWPmVpi0U5Ie7ouX_z11YbFQJaQ0RJOyeQcB1jUun_CgTWOIFjwmi7xWEhIooyC9bibW1BrWgOFBSg37jQXkbEbgBwGG1uNvHsmny-Cy3wYFa_YU3emQg71_R_92ag2ii78u2EiX69W_EY2oMofeZiIF4msANR6RxTA2AK2SzkyqB8ZeF479rG7V3IBdQIb3ZSMQtKFUQCIpzNkAE4HFrPtEZpnLmUeombl04OEJwbVyma9Hf5CQmlpe0JmV1rkgzSH8u4ALRdz-NdWrUFPRIptPlAUIwgTzEBOfbgSyZMNfFKdWHOLc7Essz3ha0pjHXm_NeITCZvS9W3Q76yIK8JdBj2P6ArKk0MCaq-jmvCVvgzUfO_lg8aPXKD6K59UangdAXaZ-1DQz0-2wK9APgVIFYew_4FB4JYlfNJaswhGCojimZbx93Yr6EHidgT8lj2',
      user_identified: false,
      clientInvoices: [],
      cartera_castigada: false,
      contentConsultaBoard: ''
    }



    let url = settings.backend.getSectionContent + 'Consulta'
    fetch(url, {
      method: 'GET',
      headers: settings.headers_super_auth,
    })
      .then(FetchResponses.processResponse)
      .then((resp) => {
        if (resp.options && resp.options.length > 0) {
          //Se hace ese filtro por si acaso la persona tiene mas opciones creadas
          let options = resp.options.filter((option) => option.active)
            .sort(function (a, b) {
              if (a.order > b.order) { return 1 }
              if (a.order < b.order) { return -1 }
              return 0;
            })

          this.setState({ contentConsultaBoard: options[0].detail })
        }
      })
      .catch(this.failureRequest)
  }

  handleChange = event => {
    this.setState(
      {
        [event.target.name]: event.target.value,
      }
    )
  }

  validateFields = () => {
    return (
      this.state.fiscal_number.length >= 4 &&
      this.state.fiscal_number.length <= 13
    );
  };


  handleConsultClient = event => {
    this.setState({ is_valid_user: false })
  }

  consultClient = event => {
    event.preventDefault();

    const data = {
      fiscal_number: this.state.fiscal_number,
      recaptcha_token: this.state.recaptcha_token,
      all_data: false,
    }
    fetch(settings.backend.consult_user_invoices, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: settings.headers_super_auth,
    })
      .then(FetchResponses.processResponse)
      .then(this.successConsultClient)
      .catch(this.failureConsultClient)
  }

  successConsultClient = response => {
    if (response.total_invoices === 0) {
      this.setState({ cartera_castigada: true })
      //Llamar cartera castigada
      let url = `${settings.backend.punishedPortfolio}/${this.state.fiscal_number}`;
      fetch(url, {
        method: 'GET',
        headers: settings.headers_super_auth,
      })
        .then(FetchResponses.processResponse)
        .then(this.allowClientAccess)
        .catch(this.failureConsultClient)

    } else {
      this.setState({ cartera_castigada: false })
      this.allowClientAccess(response)
    }
  }

  failureConsultClient = response => {
    this.setState({ is_valid_user: true })
    this.setState({ open: true, error_message: Messages.userDoesNotExists })
  }

  failureRequest = response => {
    this.setState({ open: true, error_message: response.msg })
  }

  allowClientAccess = response => {
    this.setState({ is_valid_user: true })
    this.setState({ open: true, error_message: 'El cliente entro' });
    let clientInvoices = response
    this.setState({ clientInvoices, user_identified: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  render() {

    if (this.state.user_identified) {
      return <Redirect to={{
        pathname: '/consulta/facturas_view',
        state: {
          clientInvoices: this.state.clientInvoices,
          carteraCastigada: this.state.cartera_castigada
        }
      }} />
    }

    return (
      <StaticPageStatus>
        <Helmet>
          <title>Consulta saldo - Crédito con la cedula | Crédito 20 min </title>
          <meta name="description" content="Consulta tu crédito 20 minutos para Alkomprar, Alkosto, Ktronix, Kalley, AKT y más. Recuerda solicitar tu crédito fácil y rápido con tu número de cedula ya!!" />
        </Helmet>



        <div className="gradient_background">

          <div className="consulta_container">
            <div className='hero-section consulta_slider carrusel-pc'>
              <Carrusel type="SliderConsultaPagos" bigSizeImage />
            </div>

            <div className='hero-section consulta_slider carrusel-mobile'>
              <Carrusel type="SliderConsultaPagosMobile"/>
            </div>

            <div className=" consulta_container_client">
              <div className='whitePanel consulta_container_client_content'>
                <div className="row justify-content-md-center">
                  <div className="col col-lg-12">
                    <HeaderLogo />
                  </div>
                </div>

                <div className="row justify-content-center">
                  <div className="col-10">
                    <div className='consulta_form'>


                      <form onSubmit={this.consultClient}>

                        <div className="mb-3 row form-group">
                          <div className="col-sm-10">
                            <input
                              name="fiscal_number"
                              type="text"
                              placeholder="Ingresa tu número de Cédula"
                              required
                              minLength={4}
                              maxLength={13}
                              autoFocus
                              autoComplete="off"
                              className="form-control consulta_texto_cedula"
                              value={this.state.fiscal_number}
                              onChange={this.handleChange}
                            />
                          </div>

                        </div>

                        <div className="text-center">
                          <button
                            type="submit"
                            className="btn btn-primary btn-lg consulta_boton"
                            disabled={!this.validateFields()}

                          >
                            Continuar
                          </button>
                        </div>
                      </form>
                      <>
                        {ReactHtmlParser(this.state.contentConsultaBoard)}
                      </>
                    </div>
                  </div>

                </div>
              </div>
            </div>


          </div>
          <Footer main_background white_background />
          <Error
            open={this.state.open}
            error_message={this.state.error_message}
            handleClose={this.handleClose}
          />
        </div>
      </StaticPageStatus>

    )
  }
}

export default Consult;