import {
    setDataModificacionPlazo,
  } from '../../globalState/app';
  
  export const setModificarPlazoCreditoData = data => async (dispatch, getState) => {
    dispatch({
      type: setDataModificacionPlazo,
      payload: data,
    })
  
  }