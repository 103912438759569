import React, { Component } from "react";
import HeaderPrivate from "./HeaderPrivate";
import MenuList from "./MenuList";
//import Footer from "../PublicViews/Footer";
import Footer from "../PublicViews/Footerpersonalizado";
import Logger from "../../models/Logger";
import Auth from "../../models/Auth";
import settings from "../../settings";
import FetchResponses from "../../models/FetchResponses";
import { Messages, translateTransaction } from "../../models/Messages";
import Error from "../PublicViews/Error";
import { Link, Redirect } from "react-router-dom";
import BarLoader from "react-spinners/BarLoader";
import Pagination from "react-js-pagination";
// redux
import { connect } from "react-redux";
import { SetMenuCss } from "../Actions/Menu_Actions";
import TransactionCollapse from "./TransactionCollapse";
import StaticPageStatus from "../SharedViews/StaticPageStatus";
import { ModalAux } from "./ModalAux";

//Public views
class GestionarAumentoCupo extends Component {
  constructor(props) {
      super(props);
      this.state = {
        clientInfo:{},
        fiscal_number:'',
        identified:false,
        loading:false,
        cupo_solicitar:0,
        cupo_maximo_solicitar:0,
        cupo_minimo_solicitar:0,
        checkCupoSolicitadoMaximo:true,
        checkCupoSolicitadoMinimo:true,
        habilitar_nuevo_cupo:false,
        redireccionar:false,
      };
      Logger.setLogger(this.constructor.name);
    }

  componentDidMount() {
    this.setState({
        loading: true
    });
    if(this.props.location.state){
        let stateProps = this.props.location.state;
        let formatedCupo = '$ ' + Number(String(stateProps.clientInfo.CupoPreaprobado).replace(/[^0-9\\]+/g, '')).toLocaleString('de-DE', { maximumFractionDigits: 0 })
        // let formatedCupo = '$ ' + Number(String(stateProps.clientInfo.CuotaMinimaSolicitar).replace(/[^0-9\\]+/g, '')).toLocaleString('de-DE', { maximumFractionDigits: 0 })
        this.setState({
            clientInfo:stateProps.clientInfo,
            fiscal_number:stateProps.fiscal_number,
            identified:stateProps.identified,
            cupo_solicitar:formatedCupo,
            cupo_maximo_solicitar:stateProps.clientInfo.CuotaMaximaaSolicitar,
            cupo_minimo_solicitar:stateProps.clientInfo.CuotaMinimaSolicitar,
            loading: false
        })
    }

  }

  handleOnChangeFormInput=(event)=>{
    
    let formatedNumber = Number(event.target.value.replace(/[^0-9\\]+/g, '')).toLocaleString('de-DE', { maximumFractionDigits: 0 })

    //Ponerle el signo pesos al número o dejarlo como entero
    formatedNumber = '$ ' + Number(event.target.value.replace(/[^0-9\\]+/g, '')).toLocaleString('de-DE', { maximumFractionDigits: 0 })
    this.setState({
      [event.target.name]: formatedNumber
    })
  }

  handleBlurCupoSolicitado = (event) => {      
    let checkCupoSolicitadoMaximo =  !( Number(event.target.value.replace(/[^0-9\\]+/g, '')) >  Number(this.state.cupo_maximo_solicitar) )
    let checkCupoSolicitadoMinimo =  !( Number(event.target.value.replace(/[^0-9\\]+/g, '')) < Number(this.state.cupo_minimo_solicitar) )
    this.setState({checkCupoSolicitadoMaximo , checkCupoSolicitadoMinimo}) 
  }

  handleDeseoOtroCupo =()=>{
    let stateProps = this.props.location.state;
    let formatedCupo = '$ ' + Number(String(stateProps.clientInfo.CuotaMinimaSolicitar).replace(/[^0-9\\]+/g, '')).toLocaleString('de-DE', { maximumFractionDigits: 0 })
    this.setState({  
      habilitar_nuevo_cupo:true,
      cupo_solicitar:formatedCupo,
    })
  }

  handleSolicitarCupo =(event)=>{
    this.setState({loading:true})

    let cupoSolicitar = Number(this.state.cupo_solicitar.replace(/[^0-9\\]+/g, ''))
    let data = {
        fiscal_number:this.state.fiscal_number,
        cupo_disponible:cupoSolicitar
    }
    let url = settings.backend.increaseCreditLine

    fetch(url, {
        method: "POST",
        headers: settings.headers_auth(Auth.getSessionToken()),
        body: JSON.stringify(data),
      }).then(FetchResponses.processResponse)
        .then((response) => {
          if (!!response.estado) {
            this.validConsult(response.mensaje)
          } else {
            this.failConsult()
          }
        })
        .catch(this.failConsult);
  }

  failConsult=()=>{
    this.setState({
      loading:false,
      open:true,
      error_message:Messages.errorTryLater
    })
  }

  validConsult=(message)=>{
    this.setState({
      loading:false,
      open:true,
      error_message:message
    })
  }

  handleCloseModal=()=>{
    this.setState({ open: false, redireccionar:true})
  }

  content =()=>(
    <>
    {
        (!this.state.habilitar_nuevo_cupo) && 
        <>
            <div className="col-12 row">
                <div className="d-grid gap-2 col-md-6 col-sm-12 mx-auto m-0">
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={this.handleSolicitarCupo}
                        disabled={this.state.habilitar_nuevo_cupo}
                    >
                        Aceptar cupo 
                    </button>
                </div>

                <div className="d-grid gap-2 col-md-6 col-sm-12 mx-auto m-0">
                    <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={this.handleDeseoOtroCupo}
                    >
                        Deseo otro cupo 
                    </button>
                </div>
                
            </div>
        </>
    }

    {
        (this.state.habilitar_nuevo_cupo) && 
        <>
            <hr/>

            <div className="col-11">
                <p >
                    Digita el valor total que deseas tener en tu cupo disponible.
                </p>
            </div>
            
            <div className="mb-3 col-sm-8 offset-sm-1 form-floating">
                <input
                    type="text"
                    name="cupo_solicitar"
                    id="cupo_solicitar"
                    className={`form-control ${(!this.state.checkCupoSolicitadoMaximo && !this.state.checkCupoSolicitadoMinimo) && 'is-invalid'} `}
                    placeholder="Cupo a financiar"
                    value={this.state.cupo_solicitar}
                    onChange={this.handleOnChangeFormInput}
                    onBlur={this.handleBlurCupoSolicitado}
                    required
                />
                <label htmlFor="cupo_solicitar" className="input--hat">Cupo a financiar</label>
                {(!this.state.checkCupoSolicitadoMaximo) && 
                    <p style={{ color: 'red' }}>El monto diligenciado supera el máximo cupo de aprobación </p>
                }

                {(!this.state.checkCupoSolicitadoMinimo) && 
                    <p style={{ color: 'red' }}> El cupo minimo que puede solicitar es $ {this.state.cupo_minimo_solicitar.toLocaleString('de-DE', {
                      maximumFractionDigits: 0,
                    })} </p>
                }
            </div>

            <div className="col-10 offset-1 col-sm-8 offset-sm-1 d-grid align-self-center">
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.handleSolicitarCupo}
                    disabled={!(this.state.checkCupoSolicitadoMaximo && this.state.checkCupoSolicitadoMinimo)}
                >
                    

                    Continuar <span className="icon dripicons-chevron-right icon-align-vertical" />
                </button>
            </div>
        </>
    }
    </>
  )
  
  
  element = () => (

    <div className="container-fluid">
      <div className="sidebar">
        <MenuList disabled={this.state.loading} />
      </div>
      <div className="row">
        <div className="col private-wrapper">
          <HeaderPrivate menu_list={this.state.loading} />

          <div className="wrapper">
            <StaticPageStatus>
              <div className="row justify-content-center">
                  <div className="col-lg-10 col-xl-8">
                    <div className="row">
                      <div className="col-lg-12">
                        {(this.state.identified ) &&
                           
                            <div className="panel row pe-4 ps-4 justify-content-md-cente mb-5 pb-5  mt-5 pt-5">

                                <div className="mb-3 col-sm-6 offset-sm-3 form-floating">
                                    <div className="col-lg-12 text-left" >
                                        <p style={{fontSize:'1rem'}}>
                                            Tienes un cupo preaprobado de 
                                            <span style={{fontWeight:"bold"}}> $ {this.state.clientInfo.CupoPreaprobado.toLocaleString('de-DE', {
                                                    maximumFractionDigits: 0,
                                            })}</span>
                                        </p>
                                    </div>

                                    <div className="col-lg-12 text-left" >
                                        <p style={{fontSize:'1rem'}}>
                                            del cual tienes un cupo disponible de 
                                                <span style={{fontWeight:"bold"}}> $ {this.state.clientInfo.CupoDisponible.toLocaleString('de-DE', {
                                                    maximumFractionDigits: 0,
                                            })}</span>
                                        </p>
                                    </div>
                                            
                                    {(this.state.loading)? 
                                        <>
                                            <div className="row justify-content-center">
                                                <BarLoader
                                                    sizeUnit="%"
                                                    height={5}
                                                    width={500}
                                                    color="#ff0000"
                                                    loading={this.state.loading}
                                                />
                                            </div>
                                        </> : (this.content())
                                    } 

                                </div>      
                            </div>
                         
                            
                        }
                      </div>
                    </div>  
                  </div>
              </div>
               
            </StaticPageStatus>
          </div>
          <Footer />
        </div>
      </div>

      <Error
        open={this.state.open}
        error_message={this.state.error_message}
        handleClose={this.handleCloseModal}
      />

    </div>
  );

  render() {
    if(this.state.redireccionar){
        return <Redirect to={'/resumen'}/>
    }
    else if(this.props.location.state){
        return Auth.authenticationRequired(this.element());
    }
    
    return <Redirect to={'/resumen'}/>
    
  }
    
}

export default GestionarAumentoCupo