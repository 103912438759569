import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import BarLoader from "react-spinners/BarLoader";

import HeaderPrivate from "../PrivateViews/HeaderPrivate";
import MenuList from "./MenuList";
import Footer from "../PublicViews/Footerpersonalizado";
import Logger from "../../models/Logger";
import Auth from "../../models/Auth";
import { Messages } from "../../models/Messages";
import Error from "../PublicViews/Error";

import { setModificarPlazoCreditoData } from "../Actions/ModificarPlazo_Actions";
import { SetMenuManager } from "../Actions/Menu_Actions";

import StaticPageStatus from "../SharedViews/StaticPageStatus";
import settings from "../../settings";
import FetchResponses from "../../models/FetchResponses";
import { Collapse } from "antd";
import ModificarPlazoCredito from "../PrivateViews/ModificarPlazoCredito";
import { useHistory } from 'react-router-dom';

const { Panel } = Collapse;

const ValidarModificacionPlazoCredito = ({ SetMenuManager, setModificarPlazoCreditoData }) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(Messages.errorTryLater);
  const [errorMessagePop, setErrorMessagePop] = useState(false);
  const [identificacion, setIdentificacion] = useState('')
  const [facturas, setFacturas] = useState([])
  const [dataModificacionPlazo, setDataModificacionPlazo] = useState()
  const [showModificarPlazo, setShowModificarPlazo] = useState()
  const history = useHistory();


  useEffect(() => {
    Logger.setLogger("ValidarModificacionPlazoCredito");

    // Set the menu manager in Redux and local storage
    SetMenuManager(["", "", "", "", "", "", "", "", "submit"]);
    localStorage.setItem(
      "menu",
      JSON.stringify(["", "", "", "", "", "", "", "", "submit"])
    );
  }, [SetMenuManager]);

  const handleErrorMessage = () => {
    setErrorMessagePop(false);
  };

  const handleOnBlurIdentificacion = () => {
    if(String(identificacion).length < 4) return 
    setLoading(true)
    setFacturas([])

    let data = {
      fiscal_number: identificacion,
      recaptcha_token: '03AGdBq278DPlWPmVpi0U5Ie7ouX_z11YbFQJaQ0RJOyeQcB1jUun_CgTWOIFjwmi7xWEhIooyC9bibW1BrWgOFBSg37jQXkbEbgBwGG1uNvHsmny-Cy3wYFa_YU3emQg71_R_92ag2ii78u2EiX69W_EY2oMofeZiIF4msANR6RxTA2AK2SzkyqB8ZeF479rG7V3IBdQIb3ZSMQtKFUQCIpzNkAE4HFrPtEZpnLmUeombl04OEJwbVyma9Hf5CQmlpe0JmV1rkgzSH8u4ALRdz-NdWrUFPRIptPlAUIwgTzEBOfbgSyZMNfFKdWHOLc7Essz3ha0pjHXm_NeITCZvS9W3Q76yIK8JdBj2P6ArKk0MCaq-jmvCVvgzUfO_lg8aPXKD6K59UangdAXaZ-1DQz0-2wK9APgVIFYew_4FB4JYlfNJaswhGCojimZbx93Yr6EHidgT8lj2',
      all_data: false
    }
    fetch(settings.backend.consult_user_invoices, {
      method: "POST",
      body: JSON.stringify(data),
      headers: settings.headers_auth(Auth.getSessionToken()),
    })
      .then(FetchResponses.processResponse)
      .then((response) => {
        setLoading(false)
        
        if(response.detail){
          setOpen(true)
          setErrorMessage(response.detail)
        }else if (response.invoices) {
          setFacturas(response.invoices)
        }
      })
      .catch((error) => {
        setLoading(false)
        setOpen(true)
        setErrorMessage(Messages.userDoesNotExists)

      });
  }


  const handleClicModificarPlazo = (invoice) => {

    
    let last_user = JSON.parse(Auth.getLastUserData())
    let data = {
      invoice,
      fiscal_number: identificacion,
      cuota: 0,
      user: last_user && last_user.fiscal_number ? last_user.fiscal_number : '_',
      log_modification:0
    }

    setLoading(true)
    fetch(settings.backend.validarModificacionPLazo, {
      method: "POST",
      body: JSON.stringify(data),
      headers: settings.headers_auth(Auth.getSessionToken()),
    })
      .then(FetchResponses.processResponse)
      .then(successModificarPlazo)
      .catch(failModificarPlazo);
  }

  const successModificarPlazo = (res) => {
    setLoading(false)
    if (+res.status === 201) {
     
      setOpen(true)
      setErrorMessage(res.message)
    } else if (+res.status === 200) {
      let dataModificacion = {
        data:res.data,
        originAdmin:true,
        identificacion,
        log_modification:res.log_modification
      }

      localStorage.setItem(
        "modificacion_plazo",
        JSON.stringify(dataModificacion)
      );
      setModificarPlazoCreditoData(dataModificacion)
      history.push('/modify_terms/')
    }
  }

  const failModificarPlazo = (res) => {
    setLoading(false)
    setOpen(true)
    setErrorMessage(res.detail)
  }


  const element = () => (
    <div className="container-fluid" style={{height:'100vh'}}>
      <div className="sidebar">
        <MenuList disabled={loading} />
      </div>
      <div className="row" style={{minHeight:'65vh'}}>
        <div className="col private-wrapper">
          <HeaderPrivate menu_list={loading} />
          <Error
            open={!!errorMessagePop}
            error_message={errorMessagePop}
            handleClose={handleErrorMessage}
          />
          <div className="wrapper">
            <StaticPageStatus>




              {showModificarPlazo ? <ModificarPlazoCredito data={dataModificacionPlazo} /> :
                <div className="row justify-content-center">
                  <div className="col-lg-10 col-xl-9">
                    <div className="row">
                      <div className="col-12">
                        <h1>
                          <span className="icon dripicons-network-1" />
                          Modificación plazo crédito
                        </h1>
                      </div>
                    </div>
                  </div>

                  <div className="card col-lg-10 col-xl-9 mb-4" >
                    <div className="card-body">

                      <div className="row">
                        <div className="col-12 col-lg-4">


                          <label htmlFor="identificacion" className="form-label">Digite identificación del cliente:</label>
                          <div className="input-group mb-3 col-5">
                            <input
                              type="text"
                              className="form-control"
                              id="identificacion"
                              aria-describedby="basic-addon3"
                              minLength={4}
                              value={identificacion}
                              onChange={(event) => {
                                const sanitizedValue = event.target.value.replace(/[^0-9]/g, ''); // Allow only numbers
                                setIdentificacion(sanitizedValue);
                              }}
                            />
                            <button 
                              className="btn btn-primary ms-1" 
                              disabled={identificacion.length < 4} 
                              type="button" 
                              onClick={handleOnBlurIdentificacion}
                            >Buscar</button>
                          </div>
                          {
                            (identificacion.length < 4) ? <p style={{color:'red'}}>La identificación debe ser mayor o igual a 4 dígitos</p> : ''
                          }
                        </div>


                        <br />

                        {
                          (loading) ?

                            <div className="alert alert-warning">
                              <div className="row justify-content-center">
                                <BarLoader
                                  sizeUnit='%'
                                  height={5}
                                  width={500}
                                  color='#ff0000'
                                  loading={loading}
                                /></div>
                            </div> :

                            <div className="row">
                              <div className="col-12 col-lg-6">
                                <br />
                                <p>Facturas: {facturas.length}</p>
                                {facturas.map((invoice) => (

                                  <div className="input-group mb-3" key={invoice.invoice}>
                                    <input type="text" className="form-control" value={invoice.invoice} disabled aria-label="Recipient's username" aria-describedby="button-addon2" />
                                    <button 
                                      className="btn btn-outline-secondary" 
                                      type="button"
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="top" 
                                      onClick={() => handleClicModificarPlazo(invoice.invoice)}
                                      title="Puede realizar la modificación del plazo de su obligación.
                                        En caso de haber realizado pago el día de hoy, 
                                        este no se tendrá en cuenta en la modificación."
                                    >
                                          Modificar Plazo Cuota
                                    </button>
                                  </div>

                                ))}


                              </div>
                            </div>
                        }
                      </div>
                    </div>
                  </div>

                </div>
              }



            </StaticPageStatus>
          </div>
        </div>
      </div>
      <Footer />
      <Error
        open={open}
        error_message={errorMessage}
        handleClose={() => setOpen(false)}
      />
    </div>
  );

  return Auth.authenticationRequired(element());
};

const mapDispatchToProps = {
  SetMenuManager,
  setModificarPlazoCreditoData
};

export default connect(null, mapDispatchToProps)(ValidarModificacionPlazoCredito);
