import React, { Component } from "react";
import HeaderPrivate from "../PrivateViews/HeaderPrivate";
import MenuList from "./MenuList";
// import Footer from "../PublicViews/Footer";
import Footer from "../PublicViews/Footerpersonalizado";
import Logger from "../../models/Logger";
import Auth from "../../models/Auth";
import settings from "../../settings";
import { Messages } from "../../models/Messages";
import Error from "../PublicViews/Error";
// redux
import { connect } from "react-redux";
import { SetMenuManager } from "../Actions/Menu_Actions";
import StaticPageStatus from "../SharedViews/StaticPageStatus";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-pys-custom-build";
import ReactDOM from "react-dom";

import ReactHtmlParser from "react-html-parser";
import FetchResponses from "../../models/FetchResponses";
import BarLoader from "react-spinners/BarLoader";

class Pages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      open: false,
      error_message: Messages.errorTryLater,
      showMenuPages: true,
      contents: [],
      contents_length: 0,
      currentPage: {},
    };
    Logger.setLogger(this.constructor.name);
  }

  componentDidMount = () => {
    this.props.SetMenuManager(["", "", "", "", "", "submit",""]);
    localStorage.setItem("menu", JSON.stringify(["", "", "", "", "", "submit",""]));
    this.updateContentState();
  };

  updateContentState = () => {
    this.setState({ loading: true });
    let url = settings.backend.listContent;
    fetch(url, {
      method: "GET",
      headers: settings.headers_super_auth,
    })
      .then(FetchResponses.processResponse)
      .then((response) => {
        this.setState({
          contents: response.contents,
          contents_length: response.contents_length,
          loading: false,
        });
      })
      .catch(this.failureDataResponse);
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleChange = (event) => {
    this.setState({ loading: true });
    url = settings.backend.detailContent;
    let url = `${url}${event.target.id}/`;
    fetch(url, {
      method: "GET",
      headers: settings.headers_super_auth,
    })
      .then(FetchResponses.processResponse)
      .then((response) => {
        this.setState({
          currentPage: response,
          showMenuPages: false,
          loading: false,
        });
      })
      .catch(this.failureDataResponse);

    // this.setState({ currentPage: currentPage[0],showMenuPages:false });
  };

  handleClickShowPages = () => {
    let currentPage = Object.keys(this.state.currentPage).length > 0 && {};
    let showMenuPages = !this.state.showMenuPages;
    this.setState({ showMenuPages, currentPage });
    this.updateContentState();
  };

  handleAddPage = () => {
    let currentPage = {
      id: null,
      nombre: "",
      detalle: "",
    };

    this.setState({ showMenuPages: false, currentPage });
  };

  handleOnChangeTitle = (event) => {
    this.setState({
      currentPage: {
        ...this.state.currentPage,
        [event.target.id]: event.target.value,
      },
    });
  };

  handleOnChangeDetail = (event, editor) => {
    const data = editor.getData();
    this.setState({
      currentPage: { ...this.state.currentPage, detalle: data },
    });
  };

  handleSave = () => {
    let data = this.state.currentPage;
    let dataSave;
    if (this.state.currentPage.nombre === '' || this.state.currentPage.detalle === '') {
      this.setState({
        open: true,
        error_message: 'El titulo y el detalle de la página no pueden estar vacios',
        loading: false,
      });
    } else {
      //Si se va a actualizar el usuario se debe filtrar
      if (data.user_id) {
        let { id, nombre, detalle } = data;
        dataSave = {
          id,
          nombre,
          detalle,
        };
      } else {
        dataSave = data;
      }

      fetch(settings.backend.addPage, {
        method: "POST",
        body: JSON.stringify(dataSave),
        headers: settings.headers_super_auth,
      })
        .then(FetchResponses.processResponse)
        .then(this.successCreatePage)
        .catch(this.failureDataResponse);
    }
  };

  successCreatePage = () => {
    this.setState({
      open: true,
      error_message: "Se ha guardado la página correctamente",
      loading: false,
    });
    this.handleClickShowPages();
  };

  failureDataResponse = (error) => {
    Logger.error(error);
    this.setState({
      open: true,
      error_message: Messages.errorTryLater,
      loading: false,
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  uploadAdapter = (loader) => {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("file", file);
            fetch(`${settings.backend.uploadImage}`, {
              method: "post",
              body: body,
              headers: settings.headers_auth_file(Auth.getSessionToken()),
              // mode: "no-cors"
            }).then((res) => res.json())
              .then((res) => {
                resolve({
                  default: res.fileUrl,
                });
              }).catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  };

  uploadPlugin = (editor) => {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return this.uploadAdapter(loader);
    };
  };

  convertirImagen = (image) => {
    Array.from(image).forEach((image) => {
      var reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = function () {
        var base64 = reader.result;
        let selectedFile = base64;
        const formData = new FormData();
        formData.append('file', selectedFile);
        fetch(`${settings.backend.uploadImage}`, {
          method: "post",
          body: formData,
          headers: settings.headers_auth_file(Auth.getSessionToken())
          // mode: "no-cors"
        }).then((response) => response.json())
          .then((result) => {
            //console.log("Success:", result);
          })
          .catch((error) => {
            //console.error("Error:", error);
          });
      };
    });
  };

  element = () => (
    <div className="container-fluid">
      <div className="sidebar">
        <MenuList disabled={this.state.loading} />
      </div>
      <div className="row">
        <div className="col private-wrapper">
          <HeaderPrivate menu_list={this.state.loading} />
          <div className="wrapper">
            <StaticPageStatus>
              <div className="row justify-content-center">
                <div className="col-lg-10 col-xl-11">
                  <div className="row">
                    <div className="col-lg-10">
                      <h1>
                        <span className="icon dripicons-graph-line" />
                        Administrador de la página
                      </h1>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-lg-12">
                      <div className="panel">
                        <div className="row ">
                          <div className="col-12 col-sm-2  col-md-2 col-lg-2 col-xl-1 ">
                            <p
                              style={{
                                padding: "5px",
                                margin: "0",
                                fontSize: "15px",
                              }}
                            >
                              Paginas:
                            </p>
                          </div>
                          <div className="col-12 col-sm-3 col-md-2 col-lg-2 col-xl-2 ">
                            <button className="btn btn-primary btn-sm"
                              onClick={this.handleAddPage}>
                              Agregar
                            </button>
                          </div>
                          {!this.state.showMenuPages && (
                            <div className="col-12 col-sm-3 col-md-2 col-lg-2  col-xl-2 ">
                              <button className="btn btn-outline-secondary btn-sm"
                                onClick={this.handleClickShowPages}>
                                Volver
                              </button>
                            </div>
                          )}
                        </div>
                        <div className="row justify-content-center">
                          {this.state.showMenuPages && (
                            <div className="col-11 col-sm-11 mt-3 ">
                              <div
                                className="row justify-content-center"
                                style={{
                                  borderTop: "solid",
                                  borderColor: "rgb(225, 224, 224)",
                                  borderWidth: "1px",
                                  backgroundColor: "rgba(243, 239, 238,0.3)",
                                }}>
                                <div className="col-12 ">
                                  Todas ({this.state.contents_length})
                                </div>
                                {this.state.loading ? (
                                  <div className="panel ">
                                    <div className="row justify-content-center">
                                      <BarLoader
                                        sizeUnit="%"
                                        height={5}
                                        width={500}
                                        color="#ff0000"
                                        loading={this.state.loading}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <div className="col-11 ">
                                    {Object.keys(this.state.contents).length >
                                      0 ? (
                                      <table className="table table-striped">
                                        <thead>
                                          <tr>
                                            <th className="text-left-important"
                                              scope="col">
                                              Nombre de la página
                                            </th>
                                            <th className="text-left-important" scope="col">
                                              Último Autor
                                            </th>
                                            <th className="text-left-important" scope="col">
                                              Fecha de modifiación
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {this.state.contents.map((page) => (
                                            <tr key={page.id}>
                                              <td>
                                                <a className="link_active"
                                                  onClick={this.handleChange}
                                                  id={page.id}>
                                                  {page.nombre}
                                                </a>
                                              </td>
                                              <td>{page.user}</td>
                                              <td>{page.modificado}</td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                    ) : (
                                      <span className="carrusel--subtitle mt-2">
                                        No hay páginas disponibles
                                      </span>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                          <br />
                        </div>
                        {Object.keys(this.state.currentPage).length > 0 && (
                          <>
                            <div className="row mt-4">
                              {/* <div id="hola">
                                {ReactHtmlParser(
                                  this.state.currentPage.detalle
                                )}
                              </div> */}

                              <div className="col-sm-6 carrusel--center">
                                <span className="carrusel--subtitle">
                                  Título
                                </span>
                                <input
                                  type="text"
                                  className="form-control "
                                  id="nombre"
                                  onChange={this.handleOnChangeTitle}
                                  value={this.state.currentPage.nombre}
                                />
                              </div>

                              <div className="col-12 col-sm-12 mt-3">
                                <span className="carrusel--subtitle">
                                  Contenido
                                </span>
                                <CKEditor
                                  editor={ClassicEditor}
                                  config={{
                                    toolbar: [
                                      "heading",
                                      "|",
                                      "underline",
                                      "bold",
                                      "italic",
                                      "link",
                                      "bulletedList",
                                      "numberedList",
                                      "todoList",
                                      "|",
                                      "fontBackgroundColor",
                                      "fontColor",
                                      "fontFamily",
                                      "highlight",
                                      "fontSize",
                                      "alignment",
                                      "outdent",
                                      "indent",
                                      "|",
                                      "code",
                                      "insertImage",
                                      "findAndReplace",
                                      "blockQuote",
                                      "insertTable",
                                      "mediaEmbed",
                                      "undo",
                                      "redo",
                                      "sourceEditing",
                                      "htmlEmbed",
                                      "pageBreak",
                                      "horizontalLine",
                                    ],
                                    image: {
                                      resizeOptions: [
                                        {
                                          name: "resizeImage:original",
                                          value: null,
                                          label: "Original",
                                        },
                                        {
                                          name: "resizeImage:40",
                                          value: "40",
                                          label: "40%",
                                        },
                                        {
                                          name: "resizeImage:60",
                                          value: "60",
                                          label: "60%",
                                        },
                                      ],
                                      toolbar: [
                                        {
                                          name: "Align",
                                          items: [
                                            "imageStyle:alignLeft",
                                            "imageStyle:full",
                                            "imageStyle:alignRight",
                                          ],
                                          defaultItem: "imageStyle:alignLeft",
                                        },
                                        {
                                          name: "Block",
                                          items: [
                                            "imageStyle:inline",
                                            "imageStyle:side",
                                          ],
                                          defaultItem: "imageStyle:side",
                                        },
                                        {
                                          name: "BlockAlign",
                                          items: [
                                            "imageStyle:alignBlockLeft",
                                            "imageStyle:alignCenter",
                                            "imageStyle:alignBlockRight",
                                          ],
                                          defaultItem:
                                            "imageStyle:alignBlockLeft",
                                        },

                                        "resizeImage",
                                        "|",

                                        "toggleImageCaption",
                                        "|",
                                        "imageTextAlternative",
                                      ],
                                    },
                                    table: {
                                      contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties']
                                    },
                                    extraPlugins: [this.uploadPlugin],
                                  }}
                                  data={this.state.currentPage.detalle}
                                  onReady={(editor) => { }}
                                  onChange={this.handleOnChangeDetail}
                                />
                                {/* <div className="ck-content">
                                  {ReactHtmlParser(this.state.currentPage.detalle)}
                                </div> */}
                              </div>
                            </div>

                            <div className="row justify-content-end">
                              <div className="col-12 col-sm-3 col-md-2 col-lg-2 col-xl-2 ">
                                <button
                                  className="btn btn-primary btn-sm"
                                  onClick={this.handleSave}
                                >
                                  Guardar
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </StaticPageStatus>
          </div>
        </div>
      </div>
      <Footer />
      <Error
        open={this.state.open}
        error_message={this.state.error_message}
        handleClose={this.handleClose}
      />
    </div>
  );

  render() {
    return Auth.authenticationRequired(this.element());
  }
}

const mapStateToProps = (state) => ({
  menu: state.menu.menu,
});

export default connect(mapStateToProps, { SetMenuManager })(Pages);
