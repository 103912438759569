import React, { Component } from "react";
import HeaderPrivate from "../PrivateViews/HeaderPrivate";
import MenuList from "./MenuList";
//import Footer from "../PublicViews/Footer";
import Footer from "../PublicViews/Footerpersonalizado";

import Logger from "../../models/Logger";
import Auth from "../../models/Auth";
import settings from "../../settings";
import { Messages } from "../../models/Messages";
import Error from "../PublicViews/Error";
// redux
import { connect } from "react-redux";
import { SetMenuManager } from "../Actions/Menu_Actions";
import StaticPageStatus from "../SharedViews/StaticPageStatus";
import FetchResponses from "../../models/FetchResponses";
import { Collapse } from "antd";

import MenuSection from './MenuSection' 
import Banner from "./Banner";
import PageSection from "./PageSection";

const Panel = Collapse.Panel;
class Sections extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      open: false,
      error_message: Messages.errorTryLater,
      showMenuPages: true,
      contents: [],
      contents_length: 0,
      currentSection: {},
      sectionsList: [],
      ordenRepetido: false,
      orderMessage: {},
      orderMessagePage:{},
      showCreateOptionMenu: false,
      showAddPageSection: false,
      validNewOption:{
        url:true,
        name:true,
        order:true,
        page:true,
      },
      validNewPage:{
        order:true,
        page:true,
      },
      validToUpdateOption:{ 
        idsection:'',
        option_id:'',
        url:true,
        name:true,
        order:true,
        page:true,
      },
      validToUpdatePageSection:{
        order:true,
        page:true,
      },
      validOrder:true,
      optionCreated: {
        idsection: "",
        option_id: "",
        url: "",
        name: "",
        order: "",
        content_id: "",
        page:false,
      },
      pageCreated: {
        idsection: "",
        option_id: "",
        url: "",
        name: "",
        order: "",
        content_id: "",
        page:true,
        active:true
      },
      fileList: [],
      error_message_pop: false,
      sectionDirectives:[]
    };
    Logger.setLogger(this.constructor.name);
  }

  componentDidMount = () => {
    this.props.SetMenuManager(["", "", "", "", "", "","submit"]);
    localStorage.setItem("menu", JSON.stringify(["", "", "submit", "", "", "","submit"]));
    this.updateContentState();
  };

  updateContentState = () => {
  
    //Contenidos
    this.setState({ loading: true });
    let url = settings.backend.listContent;
    fetch(url, {
      method: "GET",
      headers: settings.headers_super_auth,
    })
      .then(FetchResponses.processResponse)
      .then((response) => {
       
        this.setState({
          contents: response.contents,
          contents_length: response.contents_length,
          loading: false,
        });
      })
      .catch(this.failureDataResponse);

      //Secciones
      url = settings.backend.listSections;
      fetch(url, {
        method: "GET",
        headers: settings.headers_auth(Auth.getSessionToken()),
      })
      .then(FetchResponses.processResponse)
      .then((response) => {
        if(response){
          this.setState({ sectionsList:response.contents });
        }
        
      })
      .catch(this.failureDataResponse); 
      
      

      //Section list with the maximum
      url = settings.backend.sectionDirectives;
      fetch(url, {
        method: "GET",
        headers: settings.headers_auth(Auth.getSessionToken()),
      })
      .then(FetchResponses.processResponse)
      .then((response) => {
          this.setState({ sectionDirectives:response.contents });
      })
      .catch(this.failureDataResponse); 
  };


  //Edit option menu

  handleChangeOption = (event) => {
 
    let idsection = event.target.getAttribute("idsection");
    let idopcion = event.target.getAttribute("idopcion");
    let field = event.target.getAttribute("field");
    let value = event.target.value;


    let sectionsList = this.state.sectionsList.map((section) => {
      if (section.section_id == idsection) {
        let options = section.options.map((opcion) => {
          if (opcion.option_id == idopcion) {
            return { ...opcion, [field]: value };
          } else {
            return opcion;
          }
        });

        return { ...section, options };
      } else {
        return section;
      }
    });

    this.setState({ sectionsList });
  };

  handleBlurOrder = (event) => {

    let idsection = event.target.getAttribute("idsection");
    let idopcion = event.target.getAttribute("idopcion");
    let field = event.target.getAttribute("field");
    let value = event.target.value;


    if (field === "order") {
      let orden = event.target.getAttribute("order");
      let seccion = this.state.sectionsList.filter(
        (section) => section.section_id == idsection
      );
      let orders = seccion[0].options.map((opcion) => {
          if(opcion.active){
            return (Number(opcion.order))
          }
        }
      );

      //Con el key enviado por medio de orden se puede evaluar el arreglo
      //de acuerdo al indice para que no lo tome en cuenta en el includes
      //y no traiga problemas. Por eso elimina el primer elemento
      orders.splice(orden, 1);
      if(event.target.value===""){
        value=0;
      }
      value = orders.includes(Number(event.target.value)) ? 0 : value;
      let bool = orders.includes(Number(event.target.value)) ? true : false;

      this.setState({
        orderMessage: { idopcion, bool, value: event.target.value },
      }); 

      let sectionsList = this.state.sectionsList.map((section) => {
        if (section.section_id == idsection) {
          let options = section.options.map((opcion) => {
            if (opcion.option_id == idopcion) {
              return { ...opcion, [field]: value };
            } else {
              return opcion;
            }
          });

          return { ...section, options };
        } else {
          return section;
        }
      });

      this.setState({ sectionsList });
    }
  };

  handleSaveOption = (event) => {
    let idsection = event.target.getAttribute("idsection");
    let idopcion = event.target.getAttribute("idopcion");
    let valueOption=this.state.sectionsList.filter((section) => section.section_id == idsection)[0].
    options.filter((opcion) => opcion.option_id==idopcion);


    //revisar que los campos esten completos

    let validNombre=valueOption[0].name==="" ? false : true
    let validUrl=valueOption[0].url==="" ? false : true
    let validOrden=(valueOption[0].order==="" || valueOption[0].order=='0') ? false : true
    let validPage=(valueOption[0].page) && (!(valueOption[0].content_id=="" || valueOption[0].content_id=='0'))

   
    this.setState({validToUpdateOption:{
      idsection,
      option_id:idopcion,
      name:validNombre,
      url:validUrl,
      order:validOrden,
      page:validPage,
    }})

    
    let isPage=valueOption[0].page ;


    if(validNombre && ((!isPage && validUrl) || (isPage && validPage)) && validOrden) {
      let sectionFiletered = this.state.sectionsList.filter(
        (section) => section.section_id == idsection
      );
      let optionFiletered = sectionFiletered[0].options.filter(
        (option) => option.option_id == idopcion
      );
 

      let url;
      let content_id;

      if(optionFiletered[0].page){
        url=null
        content_id=optionFiletered[0].content_id
      }else{
       
        url=optionFiletered[0].url
        content_id=null
      }

      //Name of the section
      //Regular expression which checks if the name is between curve brackets and extract the value to be updated ex: menu2 ( PSE ) => 'PSE' 
      let section_name=!!sectionFiletered[0].name.match(/\(([^)]+)\)/)> 0 ? sectionFiletered[0].name.match(/\(([^)]+)\)/)[1] : sectionFiletered[0].name
      
      let data = { 
          section_name,
          section_id:Number(idsection),
          content_id:Number(content_id) || null,
          option_id:Number(optionFiletered[0].option_id),
          option_name:optionFiletered[0].name,
          url:url, 
          order:Number(optionFiletered[0].order), 
          active:true
        }
        
      let msg="Se han guardado los cambios correctametne";
      this.updateSection(data,msg);
    }

  };



  //Create new option menu

  handleShowCreateOption = () => {
    this.setState({ showCreateOptionMenu: true });
  };

  handleCancelCreateOption = () => {
    let optionCreated={
      idsection: "",
      option_id: "",
      url: "",
      name: "",
      order: "",
      content_id: "",
      page:false,
    }

    this.setState({optionCreated, showCreateOptionMenu: false });
  };

  handleChangeNewOptionMenu = (event) => {
    let idsection = event.target.getAttribute("idsection");
    let idopcion = event.target.getAttribute("idopcion");
    let field = event.target.getAttribute("field");
    let value = event.target.value;

    let optionCreated = {
      ...this.state.optionCreated,
      idsection,
      option_id:idopcion,
      [field]: value,
    };

    this.setState({ optionCreated });
  };

  
  handleCreateOption=(e)=>{
    e.preventDefault();

    //revisar que los campos esten completos

    let validNombre=this.state.optionCreated.name==="" ? false : true
    let validUrl=this.state.optionCreated.url==="" ? false : true
    let validOrden=this.state.optionCreated.order==="" ? false : true
    let validPage=(this.state.optionCreated.page) && (this.state.optionCreated.content_id==="" ? false : true)

    this.setState({validNewOption:{
      name:validNombre,
      url:validUrl,
      order:validOrden,
      page:validPage,
    }})

    if(validNombre && (validUrl || validPage) && validOrden) {
      //Validate order so it won´t be duplicated
      let seccion = this.state.sectionsList.filter(
        (section) => section.section_id == this.state.optionCreated.idsection
      );
      //let orders = seccion[0].options.map((opcion) => Number(opcion.order));

      let orders = seccion[0].options.map((opcion) => {
        if(opcion.active){
          return (Number(opcion.order))
          }
        }
      );

      let bool = orders.includes(Number(this.state.optionCreated.order)) ? true : false;

      bool ? this.setState({
          validOrder:false,
          optionCreated:{...this.state.optionCreated,order:''}
        })
        :this.setState({validOrder:true})

      if(!bool){
        //if everything is ok, we proceed to save the state
     
        let url;
        let content_id;

        if(this.state.optionCreated.page){
          url=null;
          content_id=Number(this.state.optionCreated.content_id);
        }else{
          url=this.state.optionCreated.url;
          content_id=null;
        }

        //Name of the section
        //Regular expression which checks if the name is between curve brackets and extract the value to be updated ex: menu2 ( PSE ) => 'PSE' 
        let section_name=!!seccion[0].name.match(/\(([^)]+)\)/)> 0 ? seccion[0].name.match(/\(([^)]+)\)/)[1] : seccion[0].name
        

        let data = { 
          section_name,
          section_id:Number(this.state.optionCreated.idsection), 
          content_id:content_id, 
          option_id:0,
          option_name:this.state.optionCreated.name,
          url:url, 
          order:this.state.optionCreated.order,
          active:true
        }
        let msg="Se ha creado una nueva opción al menú";
        
        this.updateSection(data,msg);
        this.handleCancelCreateOption();
      }
    }
  }

  handleChangeToggleOption=(value,idsection,idopcion)=>{
    

    if(idopcion===""){

      let optionCreated = {
        ...this.state.optionCreated,
        page: value,
      };
  
      this.setState({ optionCreated });

    }else{
      let sectionsList = this.state.sectionsList.map((section) => {
        if (section.section_id == idsection) {
          let options = section.options.map((opcion) => {
            if (opcion.option_id == idopcion) {
              return { ...opcion, page: value };
            } else {
              return opcion;
            }
          });
  
          return { ...section, options };
        } else {
          return section;
        }
      });
  
      this.setState({ sectionsList });
    }
  }

  handleChangeTitle=(event)=>{
    let idsection = event.target.getAttribute("idsection");
    let value = event.target.value;


    let sectionsList = this.state.sectionsList.map((section) => {
      if (section.section_id == idsection) {
        return { ...section, name:value };
      } else {
        return section;
      }
    });

    this.setState({ sectionsList });
  }

  //Section

   handleClose = () => {
    this.setState({ open: false });
  };

  handleSelectPageChange = (event) => {
    let id = event.target.getAttribute("idsection");
    let idoption = event.target.getAttribute("idoption");
    let option_name = event.target.getAttribute("nombreoption");
    let content_id = event.target.value;
    let section_name='';

    let sectionsList = this.state.sectionsList.map((section) => {
      if(section.section_id==id){
        section_name=section.name
        let options=[{...section.options[0],content_id}]
        return {...section,options}
      }else{
        return section;
      }
    })


    let currentSection = {
      section_name,
      id,
      idoption,
      option_name,
      content_id,
    };

    this.setState({ sectionsList,currentSection});
  };
  

  //Page section 2
  handleShowAddPageSection = () => {
    this.setState({ showAddPageSection: true });
  };

  handleHiddeAddPageSection = () => {
    this.setState({ showAddPageSection: false });
  };


  handleChangeNewPageSection=(event)=>{
    let idsection = event.target.getAttribute("idsection");
    let idopcion = event.target.getAttribute("idopcion");
    let field = event.target.getAttribute("field");
    let value = event.target.value;

    let pageCreated = {
      ...this.state.pageCreated,
      idsection,
      option_id:idopcion,
      [field]: value,
    };

    this.setState({ pageCreated });
  }

  handleAddPageSection=()=>{


    //revisar que los campos esten completos
    let validOrden=this.state.pageCreated.order==="" ? false : true
    let validPage=this.state.pageCreated.content_id==="" ? false : true

    this.setState({validNewPage:{
      order:validOrden,
      page:validPage,
    }})

    if( validPage && validOrden) {
      //Validate order so it won´t be duplicated
      let seccion = this.state.sectionsList.filter(
        (section) => section.section_id == this.state.pageCreated.idsection
      );
      //let orders = seccion[0].options.map((opcion) => Number(opcion.order));

      let orders = seccion[0].options.map((opcion) => {
        if(opcion.active){
          return (Number(opcion.order))
          }
        }
      );

      let bool = orders.includes(Number(this.state.pageCreated.order)) ? true : false;

      bool ? this.setState({
          validOrder:false,
          pageCreated:{...this.state.pageCreated,order:''}
        })
        :this.setState({validOrder:true})

      if(!bool){
        //if everything is ok, we proceed to save the state

        let data = { 
          section_name:seccion[0].name,
          section_id:Number(this.state.pageCreated.idsection), 
          content_id:Number(this.state.pageCreated.content_id), 
          option_id:0,
          option_name:this.state.pageCreated.name,
          url:null, 
          order:this.state.pageCreated.order,
          active:true
        }

        let msg="Se ha agregado una nueva página";
        
        this.updateSection(data,msg);

      }else{
        this.setState({
          validNewPage:{
            order:false,
          },
          showAddPageSection: true
      })
      
      }
    }
  
  }


  handleSaveSection = (event) => {

    let idsection = event.target.getAttribute("idsection");
    let idopcion = event.target.getAttribute("idopcion");
    let valueOption=this.state.sectionsList.filter((section) => section.section_id == idsection)[0].
    options.filter((opcion) => opcion.option_id==idopcion);


    //revisar que los campos esten completos

 
    let validOrden=(valueOption[0].order==="" || valueOption[0].order=='0') ? false : true
    let validPage=(valueOption[0].page) && (!(valueOption[0].content_id=="" || valueOption[0].content_id=='0'))

   
    this.setState({validToUpdatePageSection:{
      order:validOrden,
      page:validPage,
    }})


    if( validPage && validOrden) {
      let sectionFiletered = this.state.sectionsList.filter(
        (section) => section.section_id == idsection
      );
      let optionFiletered = sectionFiletered[0].options.filter(
        (option) => option.option_id == idopcion
      );
 

      let data = { 
          section_name:sectionFiletered[0].name,
          section_id:Number(idsection),
          content_id:Number(optionFiletered[0].content_id) ,
          option_id:Number(optionFiletered[0].option_id),
          option_name:optionFiletered[0].name,
          url:null, 
          order:Number(optionFiletered[0].order), 
          active:true
        }

      let msg="Se han guardado los cambios correctametne";
      this.updateSection(data,msg);
    }
      
  
  };

  handleBlurOrderPage = (event) => {

    let idsection = event.target.getAttribute("idsection");
    let idopcion = event.target.getAttribute("idopcion");
    let field = event.target.getAttribute("field");
    let value = event.target.value;


    if (field === "order") {
      let orden = event.target.getAttribute("order");
      let seccion = this.state.sectionsList.filter(
        (section) => section.section_id == idsection
      );
      let orders = seccion[0].options.map((opcion) => {
          if(opcion.active){
            return (Number(opcion.order))
          }
        }
      );

      //Con el key enviado por medio de orden se puede evaluar el arreglo
      //de acuerdo al indice para que no lo tome en cuenta en el includes
      //y no traiga problemas. Por eso elimina el primer elemento
      orders.splice(orden, 1);
      if(event.target.value===""){
        value=0;
      }
      value = orders.includes(Number(event.target.value)) ? 0 : value;
      let bool = orders.includes(Number(event.target.value)) ? true : false;

      // let validToUpdatePageSection={...this.state.validToUpdatePageSection,order};
      // this.setState({validToUpdatePageSection}); 

      this.setState({
        orderMessage: { idopcion, bool, value: event.target.value },
      }); 

      let sectionsList = this.state.sectionsList.map((section) => {
        if (section.section_id == idsection) {
          let options = section.options.map((opcion) => {
            if (opcion.option_id == idopcion) {
              return { ...opcion, [field]: value };
            } else {
              return opcion;
            }
          });

          return { ...section, options };
        } else {
          return section;
        }
      });

      this.setState({ sectionsList });
    }
  };


  //General Functions

  handleChangeSection = () => {
    //Limpio el estado temporal
    this.setState({ 
      currentSection: {},
      showAddPageSection:false ,
      showCreateOptionMenu:false,
      orderMessage:{},
      pageCreated: {
        idsection: "",
        option_id: "",
        url: "",
        name: "",
        order: "",
        content_id: "",
        page:true,
        active:true
      }, 
    });
  };

  updateSection=(data,msg)=> {
    let url = settings.backend.addSection;
    fetch(url, {
      method: "POST",
      headers: settings.headers_auth(Auth.getSessionToken()),
      body:JSON.stringify(data)
    })
    .then(FetchResponses.processResponse)
    .then((response) => {

    
      if(response){
        
        this.updateContentState();
        this.setState({
          open: true,
          error_message: msg,
          showAddPageSection:false ,
          showCreateOptionMenu:false,
        });
      }else{
        this.setState({
          open: true,
          error_message:'Ups, ha ocurrido un error, por favor comunicarse con el administrador de la página',
          showAddPageSection:false ,
          showCreateOptionMenu:false,
        });
      }
      
    })
    .catch(this.failureDataResponse);

  }

  failureDataResponse=(error) =>{
    this.setState({
      open: true,
      error_message:error.msg || 'Ups, ha ocurrido un error, por favor comunicarse con el administrador de la página',
      showAddPageSection:false ,
      showCreateOptionMenu:false,
    });
  }

  handleDisableOption = (event) => {
    let idsection = event.target.getAttribute("idsection");
    let idopcion = event.target.getAttribute("idopcion");
    let active = (event.target.getAttribute("optionactive")==="1") ? true : false;

    let sectionFiletered = this.state.sectionsList.filter(
      (section) => section.section_id == idsection
    );
    let optionFiletered = sectionFiletered[0].options.filter(
      (option) => option.option_id == idopcion
    );

    //Name of the section
    //Regular expression which checks if the name is between curve brackets and extract the value to be updated ex: menu2 ( PSE ) => 'PSE' 
    let section_name=!!sectionFiletered[0].name.match(/\(([^)]+)\)/)> 0 ? sectionFiletered[0].name.match(/\(([^)]+)\)/)[1] : sectionFiletered[0].name

    let data = { 
      section_name,
      section_id:Number(idsection), 
      content_id:Number(optionFiletered[0].content_id) || null, 
      option_id:Number(optionFiletered[0].option_id),
      option_name:optionFiletered[0].name,
      url:optionFiletered[0].url, 
      order:optionFiletered[0].order,
      active:!active
    }

    let msg= active ? "Se ha deshabilitado esta opción del menú":"Se ha habilitado esta opción del menú";
    this.updateSection(data,msg)
  };


  element = () => (
    <div className="container-fluid">
      <div className="sidebar">
        <MenuList disabled={this.state.loading} />
      </div>
      <div className="row">
        <div className="col private-wrapper">
          <HeaderPrivate menu_list={this.state.loading} />

          <Error
              open={!!this.state.error_message_pop}
              error_message={this.state.error_message_pop}
              handleClose={() => this.handleErrorMessage()}
            />

          <div className="wrapper">
            <StaticPageStatus>
              {/* Manager de la FAQ */}
              <div className="row">
                <div className="col col-lg-12">
                  <Collapse
                    bordered={false}
                    defaultActiveKey={[""]}
                    accordion
                    onChange={this.handleChangeSection}
                  >

                    <Panel header="Carrusel" key="1" className="panel">
                      <Banner type="Slider"/>
                    </Panel>

                    <Panel header="Carrusel Consulta de Pagos PC" key="2" className="panel">
                      <Banner type="SliderConsultaPagos"/>
                    </Panel>

                    <Panel header="Carrusel Consulta de Pagos Mobile" key="3" className="panel">
                      <Banner type="SliderConsultaPagosMobile"/>
                    </Panel>

                    {this.state.sectionsList.length > 0 &&
                      this.state.sectionsList.sort(function (a, b) {
                        if (a.section_id > b.section_id) { return 1 }
                        if (a.section_id < b.section_id) { return -1 }
                        return 0;
                      })
                      .map((section, key) => (
                        <Panel
                          header={section.name}
                          key={key + 4}
                          className="panel"
                        >
                          {!section.menu ? (
                        
                            <>
                              <div>
                                <h3>{section.name}</h3>
                              </div>
                              {section.options.length > 0 &&
                                  section.options.sort(function (a, b) {
                                    if (a.option_id > b.option_id) {
                                      return 1;
                                    }
                                    if (a.option_id < b.option_id) {
                                      return -1;
                                    }
                                    // a must be equal to b
                                    return 0;
                                  })
                                  .map((opcion, key) => {

                                    return(
                                      <PageSection
                                        key={opcion.option_id}
                                        listId={key}
                                        handleBlurOrder={this.handleBlurOrderPage}
                                        section={section}
                                        handleSelectPageChange={this.handleChangeOption}
                                        option={opcion}
                                        contents_length={this.state.contents_length}
                                        contents={this.state.contents}
                                        handleFirstButton={this.handleSaveSection}
                                        handleSecondButton={this.handleDisableOption} 
                                        firstButtonText='Guardar'
                                        secondButtonText={opcion.active ? "inHabilitar" : "Habilitar"}
                                        secondButtonStyle={opcion.active ? "btn btn-outline-secondary" : "btn btn-outline-success"} 
                                        firstButtonStyle='btn btn-primary'
                                        validPage={this.state.validToUpdatePageSection}
                                        orderMessage={this.state.orderMessage}
                                      />
                                    )
                                  })

                              }

                              {
                               //Validation of the maximun number of options
                               this.state.sectionDirectives.length>0 &&
                               (section.options.filter((item)=>item.active===true).length < this.state.sectionDirectives.filter((item)=> Number(item.id) === Number(section.section_id))[0].maximo) &&
                                  (
                                    this.state.showAddPageSection ? 
                                      (<PageSection
                                          section={section}
                                          handleSelectPageChange={this.handleChangeNewPageSection}
                                          option={this.state.pageCreated}
                                          contents_length={this.state.contents_length}
                                          contents={this.state.contents}
                                          handleFirstButton={this.handleAddPageSection}
                                          handleSecondButton={this.handleHiddeAddPageSection}
                                          secondButtonText='Cancelar'
                                          secondButtonStyle={"btn btn-outline-secondary"}
                                          firstButtonText='Crear'
                                          firstButtonStyle='btn btn-success'
                                          validPage={this.state.validNewPage}
                                          orderMessage={{}}
                                      />)

                                    :
                                    ( <div className="row">
                                        <div className="col-md-6 col-sm-6 col-xs-12 d-grid mt-2">
                                          <button
                                            className="btn btn-outline-danger btn-lg "
                                            onClick={this.handleShowAddPageSection}
                                          >
                                            Agregar página
                                          </button>
                                        </div>
                                        {/* <h1>Este es el maximo { this.state.sectionDirectives.filter((item)=> Number(item.id) === Number(section.section_id))[0].maximo}</h1> */}
                                      </div>)
                                )
                                    
                            }

                              
                            </>

                          ) : (
                            <>
                              {section.menu ?
                                <input
                                  type="text"
                                  className="form-control"
                                  idsection={section.section_id}
                                  name="title"
                                  onChange={this.handleChangeTitle}
                                  value={section.name}
                                />
                                :
                                <div>
                                  <h3>{section.name} </h3>
                                </div>
                              }

                              {section.options.length > 0 &&
                                section.options.sort(function (a, b) {
                                  if (a.option_id > b.option_id) {
                                    return 1;
                                  }
                                  if (a.option_id < b.option_id) {
                                    return -1;
                                  }
                                  // a must be equal to b
                                  return 0;
                                })
                                .map((opcion, key) => {

                                  
                                      return ( 
                                      <MenuSection 
                                        key={key}
                                        listId={key}
                                        opcion={opcion} 
                                        section={section} 
                                      
                                        handleChange={this.handleChangeOption} 
                                        handleBlurOrder={this.handleBlurOrder}
                                        orderMessage={this.state.orderMessage}
                                        handleFirstButton={this.handleSaveOption}
                                        handleSecondButton={this.handleDisableOption}  
      
                                        firstButtonText="Actualizar"
                                        firstButtonStyle="btn btn-primary"
                                        secondButtonText={opcion.active ? "inHabilitar" : "Habilitar"}
                                        secondButtonStyle={opcion.active ? "btn btn-outline-secondary" : "btn btn-outline-success"} 
      
                                        handleChangeToggleOption={this.handleChangeToggleOption}
      
                                        validOption={this.state.validToUpdateOption}
      
                                        contents={this.state.contents}
                                        contents_length={this.state.contents_length}
                                      />
                                      )
                                   
                                  }
                                )
                              }
                                
                              {this.state.showCreateOptionMenu ? (
                           
                                <MenuSection 
                                  key={key}
                                  opcion={this.state.optionCreated}
                                  section={section} 
                                  handleChange={this.handleChangeNewOptionMenu} 
                                 
                                  orderMessage={this.state.orderMessage}
                                  handleFirstButton={this.handleCreateOption}
                                  handleSecondButton={this.handleCancelCreateOption} 

                                  firstButtonText="Guardar"
                                  firstButtonStyle="btn btn-success"
                                  secondButtonText="Cancelar"
                                  secondButtonStyle="btn btn-outline-secondary"
                                  createMenuOption

                                  validOrder={this.state.validOrder} 
                                  validOption={this.state.validNewOption}

                                  handleChangeToggleOption={this.handleChangeToggleOption}
                                  contents={this.state.contents}
                                  contents_length={this.state.contents_length}
                                />

                                
                              ) : (
                                <div className="row">
                                  <div className="col-md-6 col-sm-6 col-xs-12 d-grid mt-2">
                                    <button
                                      className="btn btn-outline-danger btn-lg "
                                      onClick={this.handleShowCreateOption}
                                    >
                                      Crear nueva página
                                    </button>
                                  </div>
                                </div>
                              )}
                            </>
                          )}

                          <hr />
                        </Panel>
                      ))}
                  </Collapse>
                </div>
              </div>
            </StaticPageStatus>
          </div>
        </div>
      </div>
      <Footer />
      <Error
        open={this.state.open}
        error_message={this.state.error_message}
        handleClose={this.handleClose}
      />
    </div>
  );

  render() {
    return Auth.authenticationRequired(this.element());
  }
}

const mapStateToProps = (state) => ({
  menu: state.menu.menu,
});

export default connect(mapStateToProps, { SetMenuManager })(Sections);
