import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Pagination from 'react-js-pagination'

class DynamicTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      itemsPerPage: this.props.itemsPerPage,
      activePage: this.props.activePage,
      rangePages: this.props.rangePages,
      maxPages: this.props.maxPages,
      total: this.props.total
    };
  }

  render() {
    const { columns, customRender } = this.props;

    return (
      <>
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                {columns.map((col, index) => (
                  <th key={index} scope="col" className="text-center">
                    {col.title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {this.state.data.map((data, index) => {
                return (
                  <tr key={data.id || index}>
                    {columns.map((col, colIndex) => {
                      // Verificar si hay una función personalizada de renderizado
                      const customCellRender = customRender && customRender[col.field];
                      return (
                        <td key={colIndex} className="text-center">
                          {customCellRender
                            ? customCellRender(data)
                            : data[col.field] || '-'}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>


        <Pagination
          hideDisabled
          activePage={this.state.activePage}
          itemsCountPerPage={this.state.itemsPerPage}
          totalItemsCount={this.state.total}
          pageRangeDisplayed={this.state.rangePages}
          onChange={this.props.onPageChange}
          itemClass="page-item"
          linkClass="page-link"
          innerClass={`justify-content-center pagination ${this.state.total <= this.state.itemsPerPage ? 'hidden' : ''}`}
        />
      </>
    );
  }
}

DynamicTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  customRender: PropTypes.object, // Funciones de renderizado personalizado
};

DynamicTable.defaultProps = {
  customRender: {},
};

export default DynamicTable;
