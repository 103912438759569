import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import BarLoader from "react-spinners/BarLoader";

import HeaderPrivate from "../PrivateViews/HeaderPrivate";
import MenuList from "./MenuList";
import Footer from "../PublicViews/Footerpersonalizado";
import Logger from "../../models/Logger";
import Auth from "../../models/Auth";
import { Messages } from "../../models/Messages";
import Error from "../PublicViews/Error";

import { setModificarPlazoCreditoData } from "../Actions/ModificarPlazo_Actions";
import { SetMenuManager } from "../Actions/Menu_Actions";

import StaticPageStatus from "../SharedViews/StaticPageStatus";
import settings from "../../settings";
import FetchResponses from "../../models/FetchResponses";
import { Collapse } from "antd";
import ModificarPlazoCredito from "../PrivateViews/ModificarPlazoCredito";
import { useHistory } from 'react-router-dom';

const { Panel } = Collapse;

const LogModificacionPlazo = ({ SetMenuManager }) => {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState(Messages.errorTryLater);
    const getAvailableMonths = [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
    ]

    const [from, setFrom] = useState()
    const [to, setTo] = useState()
    const [month, setMonth] = useState()
    const [year, setYear] = useState()
    const [availableDays, setAvailableDays] = useState([])
    const [availableMonths, setAvailableMonths] = useState([])
    const [availableYears, setAvailableYears] = useState([])
  

    useEffect(() => {
        Logger.setLogger("LogModificacionPlazo");

        // Set the menu manager in Redux and local storage
        SetMenuManager(["", "", "", "", "", "", "", "", "", "submit"]);
        localStorage.setItem(
            "menu",
            JSON.stringify(["", "", "", "", "", "", "", "", "", "submit"])
        );

        const now = new Date()
        let day = now.getDate()
        let month = now.getMonth()
        let year = now.getFullYear()
        let available_days =getAvailableDays(month, year)
        let available_months =getAvailableMonths
        let available_years =getAvailableYears(year)


        setFrom(day)
        setTo(day)
        setMonth(month)
        setYear(year)
        setAvailableDays(available_days)
        setAvailableMonths(available_months)
        setAvailableYears(available_years)


    }, []);

    const bisiesto = year => {
        return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0
      }
    
    const getMaxDays = (month, year) => {
        const max_days = {
          0: 31,
          1: bisiesto(year) ? 29 : 28,
          2: 31,
          3: 30,
          4: 31,
          5: 30,
          6: 31,
          7: 31,
          8: 30,
          9: 31,
          10: 30,
          11: 31,
        }
        return max_days[month]
      }
    
     const getAvailableDays = (month, year) => {
        let available_days = []
        let max_days = getMaxDays(month, year)
        for (let i = 1; i <= max_days; i++) {
          available_days.push(i)
        }
        return available_days
      }
    
      const getAvailableYears = current_year => {
        let available_years = []
        for (let i = 2017; i <= current_year + 10; i++) {
          available_years.push(i)
        }
        return available_years
      }
    
    
     const downloadTransactionsCSV = () => {
       
        let url = settings.backend.logModificacionPlazo
        let options = { year: '2-digit', month: '2-digit', day: '2-digit' }
        let report_date_from = new Date(year, month, from)

        const formated_date_from = report_date_from
          .toLocaleDateString('es-CO', options)
          .replace('/', '-')
          .replace('/', '-')
    
        let report_date_to = new Date(year, month, to)
        const formated_date_to = report_date_to
          .toLocaleDateString('es-CO', options)
          .replace('/', '-')
          .replace('/', '-')
    
    
        //Url del link con ambas fechas
        url = `${url}?min_date=${formated_date_from}&max_date=${formated_date_to}`
        setLoading(true)
        fetch(url, {
          method: 'GET',
          headers: settings.headers_auth(Auth.getSessionToken()),
        })
          .then(response => {
            if (response.status === 401 && Auth.isAuthenticated()) {
              alert(Messages.sessionExpired)
              Auth.logoutUser()
            }
            if (response.status >= 200 && response.status < 300) {
              // const json_response = response.json();
              return Promise.resolve(response.text())
            } else {
              // const json_response = response.json();
              return response.then(Promise.reject.bind(Promise))
            }
          })
          .then(response => {
            if (response) {
              let csv = 'data:text/csv;charset=utf-8,' + response
              let contenido = encodeURI(csv)
              let link = document.createElement('a')
              link.setAttribute('href', contenido)
              link.setAttribute('download', 'logModificacionPlazo.csv')
              link.click()
            }
            setLoading(false)
          })
          .catch(error => {
            setLoading(false)
            setErrorMessage(Messages.errorTryLater)
            setOpen(true)
          })
      }
    
      useEffect(() => {
        updateDays()
      }, [from, to,month,year])
      
    
      const updateDays = () => {
        let available_days = getAvailableDays(month, year)
        setAvailableDays(available_days)
      }
    
    const handleClose = () => {
        setOpen(false)
      }


    const element = () => (
        <div className="container-fluid" style={{height:'100vh'}}>

            <div className="sidebar">
                <MenuList disabled={loading} />
            </div>
            <div className="row" style={{minHeight:'65vh'}}>
                <div className="col private-wrapper">
                    <HeaderPrivate menu_list={loading} />
                    <div className="wrapper">
                        <StaticPageStatus>
                            <div className="row justify-content-center">
                                <div className="col-lg-10 col-xl-8">
                                    <div className="row">
                                        <div className="col-lg-10">
                                            <h1>
                                                <span className="icon dripicons-graph-line" />
                                                Log De Modificación Plazo Cuotas
                                            </h1>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="col-lg-12">
                                            <div className="panel">
                                                <div className="col-12">
                                                    <div className="alert alert-info">
                                                        <span className="icon dripicons-information" />
                                                        Proporcione una fecha correcta para generar el archivo del log de moficación de plazo
                                                    </div>
                                                </div>
                                                <div className="row justify-content-center">
                                                    <div className="col-12 col-sm-12 col-md-5 col-lg-3">
                                                        <div className="input-group " >
                                                            <span className="input-group-text min-width-historial" >
                                                                Desde
                                                            </span>
                                                            <select
                                                                className="form-control"
                                                                id="from"
                                                                onChange={(e) => {
                                                                    setFrom(e.target.value);
                                                                }}
                                                                value={from}>
                                                                {availableDays.map(day => {
                                                                    return (
                                                                        <option value={day} key={day}>
                                                                            {day}
                                                                        </option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-12 col-md-5 col-lg-3">
                                                        <div className="input-group" >
                                                            <span className="input-group-text min-width-historial" >
                                                                Hasta
                                                            </span>
                                                            <select
                                                                className="form-control"
                                                                id="to"
                                                                onChange={(e) => {
                                                                    setTo(e.target.value);
                                                                }}
                                                                value={to}>
                                                                {availableDays.map(day => {
                                                                    return (
                                                                        <option value={day} key={day}>
                                                                            {day}
                                                                        </option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-12 col-md-5 col-lg-3">
                                                        <div className="input-group" >
                                                            <span className="input-group-text min-width-historial" >
                                                                Mes
                                                            </span>
                                                            <select
                                                                className="form-control"
                                                                id="month"
                                                                onChange={(e) => {
                                                                    setMonth(e.target.value);
                                                                }}
                                                                value={month}
                                                            >
                                                                {availableMonths.map((month, position) => {
                                                                    return (
                                                                        <option value={position} key={month}>
                                                                            {month}
                                                                        </option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-12 col-md-5 col-lg-3">
                                                        <div className="input-group" >
                                                            <span className="input-group-text min-width-historial" >
                                                                Año
                                                            </span>
                                                            <select
                                                                className="form-control"
                                                                id="year"
                                                                onChange={(e) => {
                                                                    setYear(e.target.value);
                                                                }}
                                                                value={year}
                                                            >
                                                                {availableYears.map(year => {
                                                                    return (
                                                                        <option value={year} key={year}>
                                                                            {year}
                                                                        </option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-5">
                                                        <div className="row justify-content-center">
                                                            <button
                                                                className="btn btn-primary"
                                                                onClick={downloadTransactionsCSV}
                                                                disabled={loading}
                                                            >
                                                                <span className="icon dripicons-download">Descargar Archivo</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </StaticPageStatus>
                    </div>
                </div>
            </div>
            <Footer />
            <Error
                open={open}
                error_message={errorMessage}
                handleClose={() => setOpen(false)}
            />
        </div>
    );

    return Auth.authenticationRequired(element());
};

const mapDispatchToProps = {
    SetMenuManager
};

export default connect(null, mapDispatchToProps)(LogModificacionPlazo);
