import React, { Component } from "react";
import settings from "../../settings";
import Auth from "../../models/Auth";
import FetchResponses from "../../models/FetchResponses";
import { Modal } from 'antd'
import Logger from "../../models/Logger";
// redux
import { connect } from "react-redux";
import { SetMenuManager } from "../Actions/Menu_Actions";
import AdminLayout from "../Layouts/AdminLayout";
import { Collapse } from "antd";

const Panel = Collapse.Panel;

class SettingsManager extends Component {
  confirm = Modal.confirm
  constructor(props) {
    super(props);
    Logger.setLogger(this.constructor.name);

    this.state = {
      loading: false,
      variables: [],
      values: {},  // Cambiar a un objeto para almacenar el valor de cada variable
    };
  }

  getAllVariables = () => {
    this.setState({ loading: true })
    let url = settings.backend.variables;
    fetch(url, {
      method: "GET",
      headers: settings.headers_auth(Auth.getSessionToken()),
    })
      .then(FetchResponses.processResponse)
      .then((response) => {
        this.setState({ loading: false })
        if (response) {
          this.setState({ variables: response });
        }
      })
      .catch(this.failureDataResponse);
  };

  failureDataResponse = (error) => {
    this.setState({
      open: true,
      error_message: error.msg || 'Ups, ha ocurrido un error, por favor comunicarse con el administrador de la página',
      showAddPageSection: false,
      showCreateOptionMenu: false,
    });
  };

  componentDidMount = async () => {
    this.props.SetMenuManager(["", "", "", "", "", "", "", "", "", "submit"]);
    localStorage.setItem("menu", JSON.stringify(["", "", "", "", "", "", "", "", "", "submit"]));
    this.getAllVariables();
  };

  handleInputChange = (event, variableId) => {
    const { name, value } = event.target;
    this.setState(prevState => ({
      values: {
        ...prevState.values,
        [variableId]: {
          ...prevState.values[variableId],
          [name]: value
        }
      }
    }));
  };

  activateVariable = (variableId, active, funcionalidad) => {
    let _this = this
    this.confirm({
      content: `¿Está seguro de que desea cambiar el estado de la funcionalidad de ${funcionalidad}? Este cambio se aplicará de inmediato.`,
      okText: 'Sí, cambiar estado',
      cancelText: 'Cancelar',
      onCancel: true,
      onOk() {
        _this.setState({ loading: true })
        let url = settings.backend.variables + variableId + "/";

        _this.setState((prevState) => ({
          variables: prevState.variables.map((variable) =>
            variable.id === variableId
              ? { ...variable, is_active: !active }
              : variable
          ),
        }));

        fetch(url, {
          method: "PATCH",
          headers: {
            ...settings.headers_auth(Auth.getSessionToken()),
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ is_active: !active })
        })
          .then(FetchResponses.processResponse)
          .then(() => {
            _this.getAllVariables()
            _this.setState({ loading: false })
          })
          .catch(_this.failureDataResponse);
      },
      onCancel() { },
    })
  }

  render() {
    return (
      <AdminLayout>
        <div>
          {this.state.variables.map((variable, index) => (
            <div className="panel row g-2 align-items-center" key={variable.id}>
                <div className="col-12 col-md-auto d-flex align-items-center gap-3">
                  <label htmlFor={`switch-${variable.id}`} className="fw-bold mb-0">
                    {variable.name}
                  </label>

                  <div className="form-check form-switch m-0">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id={`switch-${variable.id}`}
                      checked={variable.is_active}
                      style={{
                        transform: "scale(1.3)", // Aumentar tamaño
                        cursor: "pointer", // Mejor UX
                        backgroundColor: variable.is_active ? "green" : "",
                        borderColor: variable.is_active ? "green" : "",
                      }}
                      onChange={() =>
                        this.activateVariable(variable.id, variable.is_active, "modificación de plazos") //TODO DEJAR DE QUEMAR LA VARIABLE CUANDO SE CAMBIE A MAS VARIABLES
                      }
                    />
                  </div>
                </div>
            </div>
          ))}
        </div>
      </AdminLayout>


    );
  }
}

const mapStateToProps = (state) => ({
  menu: state.menu.menu,
});

export default connect(mapStateToProps, { SetMenuManager })(SettingsManager);
