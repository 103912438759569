import {
    setDataModificacionPlazo
} from '../../../globalState/app';

//state inicial, cada reducer debe tener su propio state
var guardado = localStorage.getItem('modificacion_plazo');
const initialState = {
    modificarPlazoInfo: JSON.parse(guardado)? JSON.parse(guardado):{
        data:[],
        origin:'',
        identificacion:'',
        privateView:false
    }
}
  

export default function(state = initialState, action){
    switch(action.type){
        case setDataModificacionPlazo:
            return{
                ...state,
                modificarPlazoInfo: action.payload,
            }
        default:
            return state;
    }
}
