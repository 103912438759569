const base = {
    super_token: process.env.REACT_APP_SUPER_TOKEN,
    host: process.env.REACT_APP_BACKEND_HOST,    
    // host: 'http://localhost:8000/',
    recaptcha_site_key: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
    google_maps_key: process.env.REACT_APP_GOOGLE_MAPS_KEY,
    maintenance_pages: process.env.REACT_APP_FRONT_MAINTENANCE || '{}',
    tagManager: process.env.REACT_APP_TAG_MANAGER || 'GTM-W3J66H2'
}

const settings = {
    recaptcha: {
        site_key: base.recaptcha_site_key,
        url_render: `https://www.google.com/recaptcha/api.js?render=${base.recaptcha_site_key}`,
    },
    maintenance_pages: (() => {
        const JSON_maint = JSON.parse(base.maintenance_pages.replace(/'/g, '"')) || {}
        
        return JSON_maint
    })(),
    maps: {
        google_maps_key: base.google_maps_key,
    },
    headers_without_auth: {
        'Content-Type': 'application/json',
    },
    headers_auth: function(token) {
        return {
            'Content-Type': 'application/json',
            Authorization: `Token ${token}`,
        }
    },
    headers_super_auth: {
        'Content-Type': 'application/json',
        Authorization: `Token ${base.super_token}`,
    },
    headers_auth_file: function(token) {
        return {
            Authorization: `Token ${token}`,
        }
    },
    backend: {

        //Aumentar cupo de crédito 
        infoClientCredit:`${base.host}v1/info_client_credit/`,
        manageCreditLine:`${base.host}v1/manage_credit_line/`,
        increaseCreditLine:`${base.host}v1/increase_credit_line/`,


        //Calcular couta
        validateClientQuoter:`${base.host}v1/validate_client_quoter/`,
        calculateQuoteQuoter:`${base.host}v1/calculate_quote_quoter/`,
        generateOtpQuoter:`${base.host}v1/generate_otp_quoter/`,
      
        //Obligaciones
        punishedPortfolio:`${base.host}v1/punished_portfolio`,

        planDePagos:`${base.host}v1/paymentplan`,
        detalleCuota: `${base.host}v1/quotapayhistory`,
        detalleDePagos:`${base.host}v1/paymentdetail`,
        uploadImage:`${base.host}v1/upload_image/`,

        send_otp: `${base.host}v1/opt_send_code/`,
        validate_otp: `${base.host}v1/opt_code_validator/`,

        // Modificar plazo crédito
        validarModificacionPLazo:`${base.host}v1/validate_modify_term/`,
        simularModificacionPLazo:`${base.host}v1/simulate_modify_term/`,
        modificarPlazoCredito:`${base.host}v1/modify_terms/`,
        sendOtpModifyTerms: `${base.host}v1/opt_send_code_modify_terms/`,
        logModificacionPlazo: `${base.host}v1/log_modification_terms/`,
        sendNotificationModifyTerms: `${base.host}v1/send_notification_start_modification/`,
        
        //notificaciones
        subscribeToPush: `${base.host}v1/webpush/subscribe/`,

        //Control admin users
        adminUsers: `${base.host}v1/admin/admin_users/`,

        //Pages
        addPage: `${base.host}v1/agregarContenido/`,
        listSections:`${base.host}v1/Sectiondetail/`,
        sectionDirectives:`${base.host}v1/listSection/`,
        addSection:`${base.host}v1/addSection/`,
        listContent:`${base.host}v1/listarContenido/`,
        detailContent:`${base.host}v1/detalleContenido/`,
        header:`${base.host}v1/header/`,
        footer:`${base.host}v1/footer/`,
        listMenu:`${base.host}v1/listmenu/`,
        getSectionContent:`${base.host}v1/getSectionContent/`,
        variables:`${base.host}v1/variables/`,
        getLogAmpliaciones:`${base.host}v1/logs/`,
        exportLogAmpliaciones:`${base.host}v1/logs/export/modificacion_plazos/`,
        totalToPay:`${base.host}v1/totaltopay`,
        consult_users_joined:`${base.host}v1/admin/users_interval/`,
        consult_user_invoices: `${base.host}v1/invoices_client/`,
        consult_user: `${base.host}v1/alk_user/`,
        pre_register: `${base.host}v1/preregister/`,
        register: `${base.host}v1/register/`,
        login: `${base.host}v1/login/`,
        logout: `${base.host}v1/logout/`,
        password_reset: `${base.host}v1/password_reset/`,
        password_reset_confirm: `${base.host}v1/password_reset_confirm/`,
        changePassword: `${base.host}v1/password_change/`,
        dataResponse: `${base.host}v1/user/`,
        summary: `${base.host}v1/summary/`,
        invoices: `${base.host}v1/invoices/`,
        states: `${base.host}v1/states/`,
        cities: state => {
            return `${base.host}v1/cities/state=${state}` 
        },
        banks: `${base.host}v1/payment/banks/`,
        pay_invoices: `${base.host}v1/payment/pay/`,
        list_transactions: `${base.host}v1/payment/list/`,
        details_transaction: transaction => {
            return `${base.host}v1/payment/details/${transaction}/`
        },
        rent_certificate: year => {
            return `${base.host}v1/alk_rent_cert/${year}/`
        },
        debt_certificate: `${base.host}v1/alk_debt_cert/`,
        all_invoices: `${base.host}v1/alk_all_invoices/`,
        peace_salve_certificate: `${base.host}v1/alk_peace_salve_cert/`,
        payment_plan_certificate: invoice => {
            return `${base.host}v1/alk_payment_plan_cert/${invoice}/`
        },
        maps_data: `${base.host}v1/stores/`,
        list_front_modules: `${base.host}v1/front_modules/list/`,
        front_modules: `${base.host}v1/front_module/`,
        admin_all_invoices: user => {
            return `${base.host}v1/admin/alk_all_invoices/${user}/`
        },
        admin_rent_cert: (user, year) => {
            return `${base.host}v1/admin/alk_rent_cert/${user}/${year}/`
        },
        admin_debt_cert: user => {
            return `${base.host}v1/admin/alk_debt_cert/${user}/`
        },
        admin_peace_salve_cert: user => {
            return `${base.host}v1/admin/alk_peace_salve_cert/${user}/`
        },
        admin_payment_plan_cert: (user, invoice) => {
            return `${base.host}v1/admin/alk_payment_plan_cert/${user}/${invoice}/`
        },
        enable: `${base.host}v1/admin/deactivate/`,
        list_user: `${base.host}v1/admin/users/`,
        conciliation_file: `${base.host}v1/payment/conciliate/`,
        csv_transactions: csv_token => {
            return `${base.host}v1/payment/report/${csv_token}/`
        },
    },
    unauthenticated_user_page: '/login',
    // unauthenticated_user_page: '/not-found',
    authenticated_user_page: '/resumen',
    authenticated_admin_page: '/manager',
    terms_and_conditions:'https://www.tdpcorbeta.com/?page=www.alkomprar.com',
    gtmId: base.tagManager
}

export default settings
