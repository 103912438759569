import React, { Component } from "react";
import HeaderPrivate from "./HeaderPrivate";
import MenuList from "./MenuList";
//import Footer from "../PublicViews/Footer";
import Footer from "../PublicViews/Footerpersonalizado";
import Logger from "../../models/Logger";
import Auth from "../../models/Auth";
import settings from "../../settings";
import FetchResponses from "../../models/FetchResponses";
import { Messages, translateTransaction } from "../../models/Messages";
import Error from "../PublicViews/Error";
import { Link, Redirect } from "react-router-dom";
import BarLoader from "react-spinners/BarLoader";
import Pagination from "react-js-pagination";
// redux
import { connect } from "react-redux";
import { SetMenuCss } from "../Actions/Menu_Actions";
import TransactionCollapse from "./TransactionCollapse";
import StaticPageStatus from "../SharedViews/StaticPageStatus";
import { ModalAux } from "./ModalAux";

// import bootstrap from 'bootstrap';

class GenerarOtp extends Component {
  constructor(props) {
    super(props);

    let last_user = JSON.parse(Auth.getLastUserData())
    this.state = {
      loading: false,
      open: false,
      error_message: Messages.errorTryLater,
      fiscal_number: last_user && last_user.fiscal_number ? last_user.fiscal_number : '_',

      monto: '',
      cantidadCuotas: '',
      cuotaMensual: '',
      valorAprobar: '',
      checkCantidadCuotas: true,
      checkCuotaMensual: true,

      cupoDisponible: '0',
      maxCuotas: '',
      minCuota: '',

      openModal: false,

      tieneCupoDisponible: true,
      openSinCupo: false,
      messageSinCupo: '',

      habilitarCamposAdicionales: false,
      showLoading: false,
      habilitarCuotas: true,
      habilitarMonto:true,

      checkMontoFinanciar:true,

    };
    this.pdf_window = null;
    Logger.setLogger(this.constructor.name);
  }

  componentDidMount() {
    //Cambia el estado del menu
    this.props.SetMenuCss(["", "", "", "", "", "", "submit"]);
    localStorage.setItem(
      "menu",
      JSON.stringify(["", "", "", "", "", "", "submit"])
    );

    this.revisarCupoDisponible();
  }

  revisarCupoDisponible = () => {
    let url = settings.backend.validateClientQuoter + this.state.fiscal_number;
    fetch(url, {
      method: 'GET',
      headers: settings.headers_auth(Auth.getSessionToken()),
    })
      .then(FetchResponses.processResponse)
      .then((response) => {

        if (Number(response.error) === 1) {
          this.setState({
            openSinCupo: true,
            messageSinCupo: response.mensaje
          })
        } else {
          if (Number(response.cupo) === 0) {
            this.setState({
              openSinCupo: true,
              messageSinCupo: response.mensaje || ''
            })
          } else {

            this.setState({
              tieneCupoDisponible: true,
              cupoDisponible: response.cupo,
              maxCuotas: Number(response.max_cuotas),
              minCuota: Number(response.min_cuota),
            })
          }
        }

      })
      .catch(this.failureCupoDisponible)
  }

  failureCupoDisponible = (message = Messages.errorTryLater) => {
    this.setState({
      openSinCupo: true,
      messageSinCupo: message
    })
  }

  handleBlurCantidadCuotasInput = (event) => {

    let valueInput = Number(event.target.value.replace(/[^0-9\\]+/g, ''))
    if (valueInput !== 0) {
      if (  valueInput > Number(this.state.maxCuotas)  ) {
        this.setState({
          checkCantidadCuotas: false,
          showLoading: false,
        },this.revisarLlamarCalcularCuotaMensual)
      } else {

        this.setState({
          checkCantidadCuotas: true,
        },this.revisarLlamarCalcularCuotaMensual)
      }
    } else {
      this.setState({
        habilitarCamposAdicionales: false,
      },this.revisarLlamarCalcularCuotaMensual)
    }
  }

  handleBlurMontoFinanciar = (event) => {      
    let checkMontoFinanciar =  !( Number(event.target.value.replace(/[^0-9\\]+/g, '')) >  Number(this.state.cupoDisponible) )
    this.setState({checkMontoFinanciar},this.revisarLlamarCalcularCuotaMensual ) 
  }

  revisarLlamarCalcularCuotaMensual = ()=>{
    if(  this.state.checkMontoFinanciar  && this.state.checkCantidadCuotas && (this.state.monto !='') &&  (this.state.cantidadCuotas !='') ){
      this.setState({
        habilitarCuotas: false,
        habilitarMonto:false
      })
      this.calcularCuotaMensual()
    }else{
      this.setState({ 
        showLoading: false,
        habilitarCamposAdicionales: false,
        habilitarCuotas: true,
        habilitarMonto: true
      })
    }
  }

  calcularCuotaMensual = () => {
    this.setState({ habilitarCamposAdicionales: false, showLoading: true })
    let url = settings.backend.calculateQuoteQuoter
    let data = {
      total: Number(this.state.monto.replace(/[^0-9\\]+/g, '')),
      cuota_inicial: 0,
      cuotas: Number(this.state.cantidadCuotas)
    }
    fetch(url, {
      method: "POST",
      headers: settings.headers_auth(Auth.getSessionToken()),
      body: JSON.stringify(data),
    }).then(FetchResponses.processResponse)
      .then((response) => {
        if (Number(response.error) === 1) {
          this.setState({
            habilitarCamposAdicionales: false,
            showLoading: false,
            habilitarCuotas: true,
            habilitarMonto: true
          })
        } else {
          let valorAprobar = '$ ' + (Number(response.cuota_mensual) * Number(this.state.cantidadCuotas)).toLocaleString('de-DE', { maximumFractionDigits: 0 })
          this.setState({
            habilitarCamposAdicionales: true,
            showLoading: true,
            cuotaMensual: '$ ' + (Number(response.cuota_mensual).toLocaleString('de-DE', { maximumFractionDigits: 0 })),
            valorAprobar,
            habilitarCuotas: true,
            habilitarMonto: true
          })

          this.checkCuotaMensualInput(Number(response.cuota_mensual))

        }
      })
      .catch(this.handleErrorServicio);
  }

  handleErrorServicio = (error)=>{
    this.setState({
      open: true
    })
  }

  checkCuotaMensualInput = (cuota) => {
    // let valueInput= Number(this.state.cuotaMensual.replace(/[^0-9\\]+/g, ''))
    if (cuota < Number(this.state.minCuota)) {
      this.setState({ checkCuotaMensual: false })
    } else {
      this.setState({ checkCuotaMensual: true })
    }
  }

  handleCloseSinCupo = () => {
    this.setState({ tieneCupoDisponible: false })
  }

  submitAumentoCupo = (event) => {
    event.preventDefault();
    let url = settings.backend.generateOtpQuoter + this.state.fiscal_number;
    fetch(url, {
      method: 'GET',
      headers: settings.headers_auth(Auth.getSessionToken()),
    })
      .then(FetchResponses.processResponse)
      .then((response) => {
        if (response.estado === 'OK') {
          let correo = response.datos_envio.data.correo
          let celular = response.datos_envio.data.celular
          this.setState({
            open: true,
            error_message: response.msj_cotizador
          })
        } else if( response.estado === 'ERROR') {
          this.failureCupoDisponible(response.estado.mensaje)
        }else{
          this.failureCupoDisponible()
        }
      })
      .catch(this.failureCupoDisponible)
  }

  handleCloseModal = () => {
    this.setState({ open: false, tieneCupoDisponible: false })
  }

  handleOnChangeFormInput = (event) => {

    let numeroEntero = event.target.getAttribute("numeroentero")
    let formatedNumber = Number(event.target.value.replace(/[^0-9\\]+/g, '')).toLocaleString('de-DE', { maximumFractionDigits: 0 })

    //Ponerle el signo pesos al número o dejarlo como entero
    if (!numeroEntero) {
      formatedNumber = '$ ' + Number(event.target.value.replace(/[^0-9\\]+/g, '')).toLocaleString('de-DE', { maximumFractionDigits: 0 })
    } else {
      formatedNumber = event.target.value.toString()
    }

    

    this.setState({
      [event.target.name]: formatedNumber
    })
  }

  handleOpenModal = () => {
    this.setState({ openModal: true })
  }

  element = () => (
    <div className="container-fluid">
      <div className="sidebar">
        <MenuList disabled={this.state.loading} />
      </div>
      <div className="row">
        <div className="col private-wrapper">
          <HeaderPrivate menu_list={this.state.loading} />

          <div className="wrapper">
            <StaticPageStatus>
              <div className="row justify-content-center">
                <div className="col-lg-10 col-xl-8">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="panel row pe-4 ps-4 justify-content-md-cente mb-5 pb-5  mt-5 pt-5">

                        <div className="col-lg-12 text-center" >
                          <p className="p_borderbotton--gray">
                            Cupo disponible
                            <span style={{ fontWeight: "bold" }}> $ {this.state.cupoDisponible.toLocaleString('de-DE', {
                              maximumFractionDigits: 0,
                            })}</span>
                          </p>
                        </div>

                        <div className="col-lg-12 text-center">
                          <p className="text-center pe-2 ps-2 ">
                            *Su saldo disponible será actualizado al siguiente dia o luego de realizar la compra
                          </p>
                        </div>

                        <div className="col-lg-12">
                          <form onSubmit={this.submitAumentoCupo} autoComplete="off" >

                            <div className="mb-3 col-sm-6 offset-sm-3 form-floating">
                              <input
                                type="text"
                                name="monto"
                                id="monto"
                                className={`form-control ${!this.state.checkMontoFinanciar && 'is-invalid'} `}
                                placeholder="Monto a financiar"
                                value={this.state.monto}
                                onChange={this.handleOnChangeFormInput}
                                onBlur={this.handleBlurMontoFinanciar}
                                disabled={!this.state.habilitarMonto}
                                required
                              />
                              <label htmlFor="monto" className="input--hat">Monto a financiar</label>
                                {(!this.state.checkMontoFinanciar) && 
                                  <p style={{ color: 'red' }}>El monto a financiar debe ser menor al cupo disponible </p>
                                }
                            </div>

                            <div className="mb-3 col-sm-6 offset-sm-3 form-floating">
                              <input
                                type="text"
                                name="cantidadCuotas"
                                id="cantidadCuotas"
                                numeroentero="true"
                                className={`form-control ${!this.state.checkCantidadCuotas && 'is-invalid'} `}
                                placeholder="Cantidad de cuotas"
                                value={this.state.cantidadCuotas}
                                onChange={this.handleOnChangeFormInput}
                                onBlur={this.handleBlurCantidadCuotasInput}
                                disabled={!this.state.habilitarCuotas}
                                required
                              />
                              <label htmlFor="cantidadCuotas" className="input--hat">Cantidad de cuotas</label>
                              {(!this.state.checkCantidadCuotas) && <p style={{ color: 'red' }}>Debe ser inferior o igual a {this.state.maxCuotas} cuotas</p>}
                            </div>

                            {
                              (this.state.habilitarCamposAdicionales) ? (
                                <>
                                  <div className="mb-3 col-sm-6 offset-sm-3 form-floating">
                                    <input
                                      type="text"
                                      name="cuotaMensual"
                                      id="cuotaMensual"
                                      className={`form-control  ${!this.state.checkCuotaMensual && 'is-invalid'} `}
                                      placeholder="Valor cuota mensual"
                                      value={this.state.cuotaMensual}
                                      onChange={this.handleOnChangeFormInput}
                                      required
                                      readOnly
                                    />
                                    <label htmlFor="cuotaMensual" className="input--hat">Valor cuota mensual</label>
                                    {(!this.state.checkCuotaMensual) &&
                                      <p style={{ color: 'red' }}>Tu valor de cuota debe ser mayor a ${this.state.minCuota.toLocaleString('de-DE', {
                                        maximumFractionDigits: 0,
                                      })}
                                      </p>
                                    }
                                  </div>

                                  <div className="mb-3 col-sm-6 offset-sm-3 form-floating">
                                    <input
                                      type="text"
                                      name="valorAprobar"
                                      id="valorAprobar"
                                      className="form-control "
                                      placeholder="Valor total a pagar"
                                      value={this.state.valorAprobar}
                                      onChange={this.handleOnChangeFormInput}
                                      required
                                      readOnly
                                    />
                                    <label htmlFor="valorAprobar" className="input--hat">Valor total a pagar
                                    </label>
                                  </div>
                                  <div className="mb-3 col-sm-6 offset-sm-3 form-floating">
                                    El valor de la cuota incluye intereses corrientes, IVA a los intereses de la financiación, fianza e IVA de la fianza. El valor de la fianza por la financiación es del 10% otorgado por el tercero FGA FONDO DE GARANTÍAS S.A. identificado con NIT 811.010.485-3 Para consultar los políticas y condiciones completas ingresa a  
                                    &nbsp;<a target="_blank" href="https://www.alkomprar.com/credito-20-minutos/legal">https://www.alkomprar.com/credito-20-minutos/legal</a>
                                  </div>
                                  <div className="col-10 offset-1 col-sm-6 offset-sm-3 d-grid align-self-center">
                                    <button
                                      type="submit"
                                      className="btn btn-primary"
                                      disabled={!(this.state.checkCantidadCuotas && this.state.checkCuotaMensual)}
                                    >
                                      Continuar <span className="icon dripicons-chevron-right icon-align-vertical" />
                                    </button>
                                  </div>
                                </>
                              ) :
                                (
                                  // Muestra el loading solo cuando hace blor la cantidad de cuotas
                                  (this.state.showLoading) && (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        marginTop: "2em",
                                      }}
                                    >
                                      <i className="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>
                                    </div>
                                  )

                                )
                            }

                          </form>
                        </div>


                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </StaticPageStatus>
          </div>
          <Footer />
        </div>
      </div>

      <Error
        open={this.state.open}
        error_message={this.state.error_message}
        handleClose={this.handleCloseModal}
      />

      <Error
        open={this.state.openSinCupo}
        error_message={this.state.messageSinCupo}
        handleClose={this.handleCloseSinCupo}
      />
    </div>
  );

  render() {
    if (!this.state.tieneCupoDisponible) {
      return <Redirect to={{ pathname: '/resumen' }} />
    }

    return Auth.authenticationRequired(this.element());
  }
}

const mapStateToProps = state => ({
  menu: state.menu.menu
});

export default connect(mapStateToProps, { SetMenuCss })(GenerarOtp);
