import React, { Component } from 'react';
import Logger from "../../models/Logger";
import settings from "../../settings";
import FetchResponses from "../../models/FetchResponses";
//redux
import { connect } from "react-redux";
import { setDataLandingTotal } from "./landing_Actions";
import { setDataFooterTotal } from "./footer_Actions";
import ReactHtmlParser from "react-html-parser";

class Footer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      landing: [],
      footercontent: [],
      stateLanding: false,
      footer: []
    };
    Logger.setLogger(this.constructor.name)
  };

  componentDidMount = () => {
    fetch(settings.backend.list_front_modules, {
      method: 'GET',
      headers: settings.headers_super_auth
    }).then(FetchResponses.processResponse)
      .then(this.successResponse)
      .catch(error => {
        Logger.error(error);
      })

    //Renderizados del backend
    let url = settings.backend.listMenu;
    fetch(url, {
      method: "GET",
      headers: settings.headers_super_auth,
    })
      .then(FetchResponses.processResponse)
      .then((response) => {
        if (response) {
          this.props.setDataFooterTotal(response.Footer);
        }
      })
      .catch(this.failureDataResponse);
  };

  successResponse = (response) => {
    this.props.setDataLandingTotal(response);
    localStorage.setItem("footer", JSON.stringify(response.modules));
    this.setState({
      landing: response.modules,
    })
  };

  element = () => (
    <div className={this.props.main_background ? (this.props.white_background ? 'footer-white': 'footer-background') : 'footer-gray'} style={{minHeight:'35vh'}}>
      <div className="footer-container">
        <div className="footer-section-main">
          {!!this.props.footer &&
            this.props.footer.length > 0 &&
            this.props.footer.sort(function (a, b) {
              if (a.order > b.order) {
                return 1;
              }
              if (a.order < b.order) {
                return -1;
              }
              return 0;
            }).filter((option) => option.active === true)
              .map((option, index) => {
                if (option.active && index <= 1) {
                  return (
                    // <div className="footer-item-main">
                    <div key={index} className="ck-content footer-item-main">
                      <div>{ReactHtmlParser(option.detail)} </div>
                    </div>
                    // </div>
                  )
                }
              })
          }
        </div>
        <div className="footer-section">
          {!!this.props.footer &&
            this.props.footer.length > 0 &&
            this.props.footer.sort(function (a, b) {
              if (a.order > b.order) {
                return 1;
              }
              if (a.order < b.order) {
                return -1;
              }
              return 0;
            }).filter((option) => option.active === true)
              .map((option, index) => {
                if (option.active && index >= 2) {
                  return (
                    // <div className="footer-item">
                    <div key={index} className="ck-content footer-item">
                      <div>{ReactHtmlParser(option.detail)} </div>
                    </div>
                    // </div>
                  )
                }
              })
          }
        </div>
      </div>
    </div>
  );

  render() {
    return this.element();
  }
}

const mapStateToProps = state => ({
  footer: state.footer.footer
});
export default connect(mapStateToProps, { setDataFooterTotal, setDataLandingTotal })(Footer);
