import React, { Component } from "react";
import settings from "../../settings";
import Logger from "../../models/Logger";
// redux
import { connect } from "react-redux";
import { SetMenuManager } from "../Actions/Menu_Actions";
import AdminLayout from "../Layouts/AdminLayout";
import { Collapse } from "antd";
import { BarLoader } from "react-spinners";
import Auth from "../../models/Auth";
import DynamicTable from "./DynamicTable";

const Panel = Collapse.Panel;

class Sections extends Component {
  constructor(props) {
    super(props);
    Logger.setLogger(this.constructor.name);

    this.state = {
      loading: false,
      cedula: "",
      full_name: "",
      fiscal_number: "",
      use_desde: false,
      use_hasta: false,
      usuario: '',
      isSearchEnabled: false,
      isDownloadEnabled: false,
      logModificacionPlazo: {
        data: [],
        total: 0,
        columns: [
          { title: 'ID del usuarios', field: 'fiscal_number' },
          { title: 'Nombre del usuario', field: 'username' },
          { title: 'Fecha y hora exacta', field: 'datetime' },
          { title: 'Estado anterior', field: 'previous_status' },
          { title: 'Estado nuevo', field: 'current_status' }
        ]
      }
    }
  }


  fetchLogs = (page = 1) => {
    const { desde, hasta, usuario } = this.state;
    const params = new URLSearchParams();

    let url = settings.backend.getLogAmpliaciones;
    if (desde) params.append("desde", desde);
    if (hasta) params.append("hasta", hasta);
    if (usuario) params.append("user_id", usuario);

    params.append("variable", 1);
    params.append("page", page);

    if (params.toString()) {
      url += `?${params.toString()}`;
    }

    this.setState({ loading: true, error: null });

    fetch(url, {
      method: "GET",
      headers: settings.headers_auth(Auth.getSessionToken())
    })
      .then(response => {
        if (!response.ok) {
          throw new Error("Error al obtener los logs");
        }
        return response.json();
      })
      .then(async data => {

        this.setState({
          isDownloadEnabled: data.count > 0,
          logModificacionPlazo: {
            data: data.results,
            total: data.count,
            next: data.next,
            activePage: page,
            previus: data.previus,
            columns: [
              { title: 'ID del usuario', field: 'fiscal_number' },
              { title: 'Nombre del usuario', field: 'username' },
              { title: 'Fecha y hora exacta', field: 'datetime' },
              { title: 'Estado anterior', field: 'previous_status' },
              { title: 'Estado nuevo', field: 'current_status' }
            ]
          }, loading: false
        });

      })
      .catch(error => {
        console.error("Error fetching logs:", error);
        this.setState({ error: "No se pudieron cargar los logs", loading: false });
      });
  };

  exportLogs = () => {
    const { desde, hasta, usuario } = this.state;
    const params = new URLSearchParams();

    let url = settings.backend.exportLogAmpliaciones
    if (desde) params.append("desde", desde);
    if (hasta) params.append("hasta", hasta);
    if (usuario) params.append("user_id", usuario);

    if (params.toString()) {
      url += `?${params.toString()}`;
    }
    
    this.setState({ loading: true, error: null });

    fetch(url, {
      method: "GET",
      headers: settings.headers_auth(Auth.getSessionToken())
    })
      .then(async response => {

        if (!response.ok) {
          throw new Error(`Error HTTP: ${response.status}`);
        }

        // Intentamos obtener el contenido como Blob (archivo)
        return response.blob();
      })
      .then(blob => {
        // Crear un enlace para descargar el archivo
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "log_modificacion_plazos.xlsx";
        document.body.appendChild(a);
        a.click();
        a.remove();
        this.setState({ loading: false, error: null });
      })
      .catch(error => {
        this.setState({ loading: false, error: null });
        console.error("Error fetching logs:", error);
      });
  }


  handleSubmit = (e) => {
    e.preventDefault();
    this.fetchLogs();
  };

  ampliacionesContent = () => {
    if (this.state.loading) {
      return (
        <div className="alert alert-warning">
          <div className="row justify-content-center">
            <BarLoader
              sizeUnit='%'
              height={5}
              width={500}
              color='#ff0000'
              loading={this.state.loading}
            /></div>
        </div>
      )
    } else if (this.state.logModificacionPlazo.data.length === 0) {
      return (
        <div className="alert alert-warning">
          <div className="row justify-content-center">
            <h3> No se encontraron resultados que coincidan con los criterios de búsqueda. Por favor, verifica los filtros y vuelve a intentarlo.</h3>
          </div>
        </div>
      );
    } else {
      return (
        <DynamicTable
          data={this.state.logModificacionPlazo.data}
          columns={this.state.logModificacionPlazo.columns}
          customRender={this.state.logModificacionPlazo.customRender}
          total={this.state.logModificacionPlazo.total}
          itemsPerPage={10}
          activePage={this.state.logModificacionPlazo.activePage}
          rangePages={5}
          maxPages={0}
          onPageChange={this.handlePageChange}
        />
      )
    }
  }

  handlePageChange = (active_page) => {
    this.fetchLogs(active_page);
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value }, this.validateSearch);
  };

  validateSearch = () => {
    const { desde, usuario } = this.state;
    const isSearchEnabled = !!desde || !!usuario; // Al menos uno debe tener valor
    this.setState({ isSearchEnabled });
  };

  handleDownload = () => {
    this.exportLogs();
  }

  render() {
    const { desde, hasta, usuario } = this.state;

    return (
      <AdminLayout>
        <Collapse bordered={false}
          defaultActiveKey={[""]}
          accordion
          onChange={this.handleChangeSection}>
          <Panel header="Log Modificación Plazos" key="1" className="panel">
            <div className="col-lg-12">
              <form onSubmit={this.handleSubmit} className="row g-2 align-items-center">
                <div className="col-12 col-md-auto">
                  <label className="mb-0">Desde:</label>
                  <input
                    className="form-control"
                    type="date"
                    name="desde"
                    value={desde}
                    onChange={this.handleInputChange}
                  />
                </div>

                <div className="col-12 col-md-auto">
                  <label className="mb-0">Hasta:</label>
                  <input
                    className="form-control"
                    type="date"
                    name="hasta"
                    value={hasta}
                    onChange={this.handleInputChange}
                  />
                </div>

                <div className="col-12 col-md-auto">
                  <label className="mb-0">Usuario:</label>
                  <input
                    className="form-control"
                    type="text"
                    name="usuario"
                    value={usuario}
                    onChange={this.handleInputChange}
                    placeholder="Ingrese usuario"
                  />
                </div>

                <div className="col-12 col-md-auto">
                  <button
                    className="btn btn-primary"
                    type="submit"
                    disabled={!this.state.isSearchEnabled}
                  >
                    Buscar
                  </button>
                </div>

                <div className="col-12 col-md-auto">
                  <button
                    className="btn btn-success"
                    type="button"
                    onClick={this.handleDownload}
                    disabled={!this.state.isDownloadEnabled}
                  >
                    Descargar Reporte
                  </button>
                </div>
              </form>
            </div>

            <br />
            <br />
            {this.ampliacionesContent()}
          </Panel>
        </Collapse>

      </AdminLayout>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { SetMenuManager })(Sections);
